import React, { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'
import useIsIframe from '../CounsellorUser/CounsellorsClientPortFolio/common/IsIframe'

interface SearchBarProps {
  onSearch: (searchTerms: {
    company: string
    jobTitle: string
    location: string
    role: string
  }) => void
}

// Desktop Search Bar Component
const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerms, setSearchTerms] = useState({
    company: '',
    jobTitle: '',
    location: '',
    role: '',
  })

  const debouncedSearch = debounce(() => {
    onSearch(searchTerms)
  }, 300)

  useEffect(() => {
    debouncedSearch()
  }, [searchTerms, debouncedSearch])

  const handleInputChange = (
    field: keyof typeof searchTerms,
    value: string
  ) => {
    setSearchTerms((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  return (
    <div className="fixed top-16 left-0 right-0 bg-white z-10 px-6 pt-2">
      <div className="flex gap-4 mb-2">
        <div className="flex flex-grow border border-gray-300 rounded-md overflow-hidden">
          <input
            type="text"
            placeholder="Job Title"
            className="flex-grow px-4 py-2 border-r border-gray-300"
            value={searchTerms.jobTitle}
            onChange={(e) => handleInputChange('jobTitle', e.target.value)}
          />
          <input
            type="text"
            placeholder="Location"
            className="flex-grow px-4 py-2 border-r border-gray-300"
            value={searchTerms.location}
            onChange={(e) => handleInputChange('location', e.target.value)}
          />
          
          <button className="bg-[#0778B6] text-white px-4 hover:bg-blue-700 transition duration-300">
            Search
          </button>
        </div>
      </div>
    </div>
  )
}

// Mobile Search Bar Component
export const MobileSearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerms, setSearchTerms] = useState({
    company: '',
    jobTitle: '',
    location: '',
    role: '',
  })

  const isIframe = useIsIframe();

  const debouncedSearch = debounce(() => {
    onSearch(searchTerms)
  }, 300)

  useEffect(() => {
    debouncedSearch()
  }, [searchTerms, debouncedSearch])

  const handleInputChange = (
    field: keyof typeof searchTerms,
    value: string
  ) => {
    setSearchTerms((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  return (
    <div className={`fixed ${!isIframe ? 'top-16' :'top-[-2px]' }   left-0 right-0 bg-white z-10 p-4 border-b border-gray-200`}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-grow border border-gray-300 rounded-md overflow-hidden">
          <input
            type="text"
            placeholder="Job Title"
            className="flex-grow px-1 py-2 border-r border-gray-300"
            value={searchTerms.jobTitle}
            onChange={(e) => handleInputChange('jobTitle', e.target.value)}
          />
          <input
            type="text"
            placeholder="Location"
            className="flex-grow px-1 py-2 border-r border-gray-300"
            value={searchTerms.location}
            onChange={(e) => handleInputChange('location', e.target.value)}
          />
        </div>
        <button className="bg-[#0778B6] text-white p-2 rounded-md hover:bg-blue-700 transition duration-300 text-sm">
          Find Matching Jobs
        </button>
      </div>
    </div>
  )
}

export default SearchBar
