import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HRMAN from "../../../assets/img/HR/HRMAN.svg";
import key from "../../../assets/img/key.svg";
import emailicon from "../../../assets/img/Emailicon.svg";
import Loginnav from "../../Navbar/loginNav";
import { TextField, InputAdornment } from "@mui/material"; // Import necessary MUI components
import { send_Otp, validate_otp } from "../../../api/UserMetadata";
import { checkIfHR } from "../../../api/HrJobs";


const HRLoginMobile: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [otpValue, setOtpValue] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false); // To track if OTP is sent
  const [timeLeft, setTimeLeft] = useState<number>(0); // To track time left before next OTP can be sent
  const [otpAttempts, setOtpAttempts] = useState<number>(0); // Track the number of OTP resend attempts
  const [hrError, setHrError] = useState<string>(""); // State to handle HR error message
  const navigate = useNavigate();

  // Function to validate email
  const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  // Function to handle OTP validation
  const isValidOtp = async (otp: string) => {
    const isValid=await validate_otp(email,otp)
    
    return isValid; // The hardcoded valid OTP for now
  };

  // Handle sending OTP
  const sendOtp = async () => {
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return; // Stop if the email is invalid
    }
    setEmailError(""); // Clear error if email is valid
  
    // Check if the user is an HR before sending OTP
    try {
      const hrStatus = await checkIfHR(email);
      if (!hrStatus.is_HR) {
        setHrError("You are not authorized to log in as HR.");
        return; // Stop if the user is not an HR
      }
      setHrError("");
      // If the user is HR, proceed with OTP sending
      const response: any = await send_Otp(email);
      if (response.success) {
        alert(`OTP successfully Sent to ${email}!`);
        setOtp(true); // Proceed with OTP sending
        setIsOtpSent(true); // Mark OTP as sent
        setTimeLeft(30); // Start the 30 seconds countdown
      } else {
        alert("Something Went Wrong Please Try Again!");
      }
    } catch (error) {
      console.error("Failed to check HR status:", error);
      setHrError("Failed to verify HR status. Please try again.");
    }
  };

  // Countdown timer to manage 30 seconds delay between OTP sends
  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined; // Type-safe interval timer

    if (isOtpSent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Decrease time every second
    } else if (timeLeft === 0) {
      clearInterval(timer); // Clear timer when countdown finishes
    }

    return () => {
      if (timer) {
        clearInterval(timer); // Cleanup interval when component unmounts or timeLeft reaches 0
        setIsOtpSent(true);
      }
    };
  }, [isOtpSent, timeLeft]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(""); 
    setHrError(""); 
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value);
  };

  const validateOtp = async () => {
    const isValid = await isValidOtp(otpValue);
    if (!isValid) {
      setOtpError("Invalid OTP. Please enter the correct OTP.");
    } else {
      setOtpError(""); // Clear error if OTP is valid
      localStorage.clear();
      localStorage.setItem("email", email); // Save email in localStorage
      localStorage.setItem("userLevel", "HR"); 
      navigate("/jobs/HRPortfolio"); // Navigate without state
    }
  };

  const resendOtp = async () => {
    if (otpAttempts >= 3) {
      alert("You have reached the maximum resend attempts.");
      return; // Stop if more than 3 attempts
    }

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return; // Stop if the email is invalid
    }
    setEmailError(""); // Clear error if email is valid
    sendOtp()
    setOtpAttempts((prevAttempts) => prevAttempts + 1); // Increment the attempt count
  };

  return (
    <div className="overflow-y-auto flex flex-col md:flex-row md:h-screen h-screen">
      <div className="flex flex-col md:w-1/2">
        <Loginnav bgColor="bg-[#FEF5E6] " />
        <div className="flex flex-col items-center mb-10">
            <span className="relative max-h-[300px] rounded-b-[700px] bg-[#FEF5E6] min-w-auto">
                {/* HR LOGIN centered at the top of the image */}
                <span className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl font-semibold md:text-4xl font-sans bg-custom-gradient text-transparent bg-clip-text">
                    HR LOGIN
                </span>
                <img
                src={HRMAN}
                alt="Login"
                className="pt-[-100px] w-[600px] h-[400px] top-[-150px] mb-[-150px]"
                />
            </span>
        </div>

        <div className="flex  md:bottom-0 md:w-1/2 flex flex-col justify-start items-center ">
         

          {/* Email input section */}
          <div className="w-full max-w-sm pl-2 pr-2 pt-[100px] ">
            {/* Material UI TextField with Email Icon */}
            <TextField
              id="email"
              label="Email"  // Label that appears at the top border
              value={email}
              onChange={handleEmailChange}
              placeholder="Please enter your email"  // Placeholder text inside the box
              fullWidth
              disabled={isOtpSent}
              className="my-4"  // Adding margin around the input
              InputLabelProps={{
                shrink: true, // Ensures the label stays above even when the input is empty
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                   <img
                        src={emailicon}
                        alt="Login"
                        className="object-cover"
                      />
                  </InputAdornment>
                ),
              }}
              error={!!emailError} // Show error if email is invalid
              helperText={emailError} // Display error message
            />
          </div>
          {hrError && (
            <div className="text-red-500 mt-2">{hrError}</div> // Display HR error if user is not HR
          )}

          {otp && (
            <div className="flex flex-col w-full max-w-sm pl-2 pr-2  mt-5">
              <TextField
                id="OTP"
                label="OTP"  // Label that appears at the top border
                value={otpValue}
                onChange={handleOtpChange}
                placeholder="Please enter OTP here"  // Placeholder text inside the box
                fullWidth
                className="my-4"  // Adding margin around the input
                InputLabelProps={{
                  shrink: true, // Ensures the label stays above even when the input is empty
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={key}
                        alt="Login"
                        className="object-cover"
                      />
                    </InputAdornment>
                  ),
                }}
                error={!!otpError} // Show error if OTP is invalid
                helperText={otpError} // Display error message
              />
              {/* Resend OTP link */}
              <button
                className={`flex justify-end text-right font-sans text-sm ${otpAttempts >= 3? "text-gray-500":"text-black-500"} cursor-pointer mt-1 mr-2`}
                onClick={resendOtp}
                disabled={timeLeft > 0 || otpAttempts >= 3} // Disable if cooldown or attempts are maxed
              >
                {timeLeft > 0 ? `Resend in ${timeLeft}s` : "Resend OTP"}
              </button>
            </div>
          )}

          {/* Send OTP Button */}
          {otp ? (
            <div className="flex items-center justify-center mt-4 md:py-3">
              <button
                onClick={validateOtp}
                className="relative w-[180px] h-[50px] font-sans text-lg font-semibold tracking-wider text-white bg-[#0778B6] rounded-full"
              >
                LOGIN
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-center py-5 mt-10 pt-10 md:py-3">
              <button
                onClick={sendOtp}
                className="relative w-[180px] h-[50px] font-sans text-lg font-semibold tracking-wider text-white bg-[#0778B6] rounded-full"
              >
                SEND OTP
              </button>
            </div>
          )}

          <p className="md:text-base justify-center pt-2 text-sm font-normal font-sans text-center">
            Want to switch to User Login?
            <Link to="/login" className="pl-1 text-custom-blue">
              Click here to continue.
            </Link>
          </p>
          <div className="flex flex-col mt-auto  pt-auto">
            <p className="md:text-base justify-center text-sm font-semibold font-sans text-center pt-10 mt-10">
                Having issue with your login?
                <Link to="/contactUs" className="pl-1 text-custom-blue underline">
                Contact Us.
                </Link>
            </p>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default HRLoginMobile;
