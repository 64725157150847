import { BackendApi } from "./apiConfig/ApiFormatter";

export const createHRJob = async (payload: Record<string, any>): Promise<any> => {
  const apiUrl = `${BackendApi()}hrjobs/create`; 

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to create HR job:', error);
    throw error;
  }
};

export const updateHRJob = async (job_id: string,updateData: Record<string, any>): Promise<any> => {
  const apiUrl = `${BackendApi()}hrjobs/update`;

  const payload = {job_id,update_data: updateData,};

  try {
    const response = await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || `HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error: any) {
    throw new Error(`Failed to update HR job: ${error.message || error}`);
  }
};

export const deleteHRJob = async (job_id: string): Promise<any> => {
  const apiUrl = `${BackendApi()}hrjobs/delete`;

  const payload = {job_id};

  try {
    const response = await fetch(apiUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to delete HR job', error);
    throw error;
  }
};

export const searchHRJobs = async (params: Record<string, any>): Promise<any> => {
  const query = new URLSearchParams(params).toString();
  const apiUrl = `${BackendApi()}hrjobs/search?${query}`;

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {},
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message || `HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to search HR jobs:', error);
    throw error;
  }
};

export const getHRDetails = async (email: string) => {
  const apiUrl = `${BackendApi()}hrregister/read?email=${encodeURIComponent(email)}`;

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); // Dynamically infer the response type
  } catch (error) {
    console.error('Failed to fetch HR details:', error);
    throw error;
  }
};

export const checkIfHR = async (email: string) => {
  const apiUrl = `${BackendApi()}isHR`;

  try {
    // Make a POST request to check if the user is an HR, using email only for auth
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // No need for Authorization header
      },
      body: JSON.stringify({ email }), // Only pass email
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the response and return the result
    return await response.json(); // Expected: { email: string, is_HR: boolean }
  } catch (error) {
    console.error('Failed to check HR status:', error);
    throw error;
  }
};
