import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CollegePortfolioDeskView from '../components/College/desktop/CollegePortfolioDeskView';
import CollegePortfolioDesktop from '../components/College/desktop/CollegePortfolioDeskptop';
import CollegePortfolioMobile from '../components/College/mobile/CollegePortfolioMobile';



const CollegePortfolio: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className="flex flex-col">
      {/* <CreateNavbar page='home' /> */}
      {isMobile ? (
        <CollegePortfolioMobile/>
      ) : (
        <CollegePortfolioDesktop/>
      )}
    </div>
  );
};

export default CollegePortfolio;
