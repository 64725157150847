import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CollegeDashboardDesktop from '../components/College/CollegeDashboardDesktop';
import CollegeDashboardMobile from '../components/College/CollegeDashboardMobile';

const CollegeDashboardMainPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className="flex flex-col">
      {isMobile ? (
        <CollegeDashboardMobile/>
      ) : (
        <CollegeDashboardDesktop/>
      )}
    </div>
  );
};

export default CollegeDashboardMainPage;
