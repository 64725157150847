import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import key from "../../assets/img/key.svg";
import emailicon from "../../assets/img/Emailicon.svg";
import { TextField, InputAdornment } from "@mui/material"; // Import necessary MUI components
import { getUserMetadata, send_Otp, storeUserName, validate_otp } from "../../api/UserMetadata";
import Loginnav from "../Navbar/loginNav";
import login from "../../assets/img/login.png";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CreateUser } from "../../api/CreateUser";
import LoadingSpinner from "../common/LoadingSpinner";
import defaultAvtar from '../../assets/img/Avatar.png';

interface EmailLoginMobileProps {
  onBack?: () => void;
  initialShowNameInput?: boolean;
  initialEmail?: string;
}

const EmailLoginMobile: React.FC<EmailLoginMobileProps> = ({ onBack,initialShowNameInput = false,initialEmail = ""  }) => {
  const [email, setEmail] = useState<string>(initialEmail);
    const [otp, setOtp] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>("");
    const [otpValue, setOtpValue] = useState<string>("");
    const [otpError, setOtpError] = useState<string>("");
    const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(0);
    const [otpAttempts, setOtpAttempts] = useState<number>(0);
    const [Error, setError] = useState<string>("");
    const [showNameInput, setShowNameInput] = useState(initialShowNameInput);
    const [fullName, setFullName] = useState("");
    const [nameError, setNameError] = useState("");
    const [accessTokenTemp, setAccessTokenTemp] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const location: any = useLocation();
    const { page } = location.state || { page: 'No text provided' };

  const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const sendOtp = async () => {
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return; 
    }
    setEmailError(""); 
  
    try {
      const response: any = await send_Otp(email);
      if (response.success) {
        alert(`OTP successfully Sent to ${email}!`);
        setOtp(true); 
        setIsOtpSent(true); 
        setTimeLeft(30);
      } else {
        alert("Something Went Wrong Please Try Again!");
      }
    } catch (error) {
      console.error("Failed to check User status:", error);
      setError("Failed to verify User status. Please try again.");
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined; 

    if (isOtpSent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); 
    } else if (timeLeft === 0) {
      clearInterval(timer); 
    }

    return () => {
      if (timer) {
        clearInterval(timer); 
        setIsOtpSent(true);
      }
    };
  }, [isOtpSent, timeLeft]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(""); 
    setError(""); 
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
    setNameError("");
  };

  const appendTokenToUrl = (decodedUrl: string, token: string): string => {
    const url = new URL(decodedUrl);
    url.searchParams.set('ssoToken', token);
    return url.toString();
  };

  const handleNavigation = (accessToken: string) => {
    if (page === "counselling") {
      navigate("/counselling");
    } else if (page === "pcj") {
      navigate('/premiumjourney');
    } else if (page === "portfolio") {
      navigate('/portfolio');
    } else if (location.search.includes('returnurl')) {
      const encodedUrl = new URLSearchParams(location.search).get('returnurl');
      const decodedUrl = decodeURIComponent(encodedUrl || '');
      
      if (decodedUrl) {
        window.location.href = appendTokenToUrl(decodedUrl, accessToken);
      }
    } else {
      navigate('/home'); 
    }
  };

  const handleSubmitName = async () => {
    const trimmedName = fullName.trim();
    
    if (trimmedName.length > 50 || trimmedName.length < 2) {
        setNameError(trimmedName.length > 50 ? "Full name cannot exceed 50 characters." : "Please enter at least 2 characters." );
        return;
    }
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(fullName.trim())) {
        setNameError("Name must contain only letters and spaces.");
        return;
    }
    try {
      const storedEmail = localStorage.getItem('Email') || email;
      await CreateUser();
      await storeUserName(storedEmail, fullName);
      setLoading(true); 
      localStorage.setItem("Name", fullName);
      localStorage.setItem('profileIcon', defaultAvtar);
      
      const userData = await getUserMetadata(accessTokenTemp);
      handleNavigation(accessTokenTemp);
    } catch (error: any) {
      setNameError(error.error || "Failed to save name. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const validateOtp = async () => {
    try {
      const response = await validate_otp(email, otpValue);
      if (response && response.isValidOtp) {
        const accessToken = response.accessToken;
        const name = response.name;
        const profileIcon = response.profileIcon || defaultAvtar;
        localStorage.setItem('AccessToken', `Bearer ${accessToken}`);
        localStorage.setItem('LMSToken', `${accessToken}`)
        localStorage.setItem('Email', email);
        
  
        if (name !== null) {
          localStorage.setItem('Name', name);
          localStorage.setItem('profileIcon', profileIcon);
          setLoading(true);
  
          const userData = await getUserMetadata(accessToken);
          if (userData) {
            handleNavigation(accessToken);
          }
        } else {
          setAccessTokenTemp(accessToken);
          setShowNameInput(true);
        }
      } else {
        setOtpError("Invalid OTP. Please enter the correct OTP.");
      }
    } catch (error) {
      setOtpError("Failed to validate OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  const resendOtp = async () => {
    if (otpAttempts >= 3) {
      alert("You have reached the maximum resend attempts.");
      return; 
    }

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setEmailError(""); 
    sendOtp();
    setOtpAttempts((prevAttempts) => prevAttempts + 1); 
  };

  const handleBackButtonClick = () => {
    if (onBack) {onBack()}
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col h-screen overflow-y-auto">
      <Loginnav />

      <div className="flex justify-center items-center mb-8">
        <img src={login} alt="Login" className="w-80 h-64" />
      </div>

      <div className="bg-[#FEF5E6] rounded-custom-70px-tl-tr shadow-mobiledrop px-4 py-6 flex flex-col items-center h-full">
        <div className="flex w-full justify-start p-1">
          <button
            className="text-black hover:text-gray-700 transition-colors"
            onClick={handleBackButtonClick}
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </button>
        </div>
        <span className="text-xl font-semibold md:text-4xl font-sans text-black mb-6">
          Continue With Email
        </span>

        {!showNameInput ? (
          <>
            <div className="w-full max-w-sm">
              <TextField
                id="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Please enter your email"
                fullWidth
                disabled={isOtpSent}
                className="my-4"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={emailicon}
                        alt="Email Icon"
                        className="w-5 h-5 object-contain"
                      />
                    </InputAdornment>
                  ),
                }}
                error={!!emailError}
                helperText={emailError}
              />
            </div>

            {otp && (
              <div className="w-full max-w-sm mt-5">
                <TextField
                  id="OTP"
                  label="OTP"
                  value={otpValue}
                  onChange={handleOtpChange}
                  placeholder="Please enter OTP here"
                  fullWidth
                  className="my-4"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={key}
                          alt="Key Icon"
                          className="w-5 h-5 object-contain"
                        />
                      </InputAdornment>
                    ),
                  }}
                  error={!!otpError}
                  helperText={otpError}
                />
                <button
                  className={`flex justify-end text-right font-sans text-sm ${
                    otpAttempts >= 3 ? 'text-gray-500' : 'text-black-500'
                  } cursor-pointer mt-1`}
                  onClick={resendOtp}
                  disabled={timeLeft > 0 || otpAttempts >= 3}
                >
                  {timeLeft > 0 ? `Resend in ${timeLeft}s` : 'Resend OTP'}
                </button>
              </div>
            )}

            {otp ? (
              <div className="flex items-center justify-center mt-6">
                <button
                  onClick={validateOtp}
                  className="w-40 h-12 font-sans text-lg font-semibold tracking-wider text-white bg-[#0778B6] rounded-full"
                >
                  LOGIN
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-center mt-6">
                <button
                  onClick={sendOtp}
                  className="w-40 h-12 font-sans text-lg font-semibold tracking-wider text-white bg-[#0778B6] rounded-full"
                >
                  SEND OTP
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="w-full max-w-sm mt-5">
            <TextField
              id="fullName"
              label="Full Name"
              value={fullName}
              onChange={handleNameChange}
              placeholder="Please enter your full name"
              fullWidth
              className="my-4"
              InputLabelProps={{ shrink: true }}
              error={!!nameError}
              helperText={nameError}
            />
            <div className="flex items-center justify-center mt-4">
              <button
                onClick={handleSubmitName}
                className="w-40 h-12 font-sans text-lg font-semibold tracking-wider text-white bg-[#0778B6] rounded-full"
              >
                SUBMIT
              </button>
            </div>
          </div>
        )}

        <div className="mt-6">
          <p className="text-sm md:text-base font-semibold font-sans text-center">
            Having issue with your login?{' '}
            <Link to="/contactUs" className="text-custom-blue underline">
              Contact Us.
            </Link>
          </p>
        </div>
      </div>
    </div>
  ) ;
};

export default EmailLoginMobile;
