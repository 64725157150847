import CreateNavbar from "../Navbar/Navbar";
import SearchIcon from '@mui/icons-material/Search';
import MIT_logo from '../../assets/img/CollegeDashboard/MIT_logo.png'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { searchStudents } from "../../api/College";
import LoadingSpinner from "../common/LoadingSpinner";
import { isUserTokenValid } from "../common/ValidateToken";
export interface Student {
  id?: string; 
  name: string;
  ProfileUrl: string;
  education: string;
  cgpa: string;
  highlights: string;
  email?: string;
  college_name?: string;
  contact_number?: number;
  degree?: string;
  department?: string;
  year?: number;
}

interface Filter {
  category: string;
  value: string | number;
}

interface FilterOptions {
  degrees: string[];
  departments: string[];
  years: number[];
}

const CollegeDashboardDesktop: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    degrees: [],
    departments: [],
    years: []
  });
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [initialStudents, setInitialStudents] = useState<Student[]>([]);
  const [lastSearchResults, setLastSearchResults] = useState<Student[]>([]); 
  const navigate = useNavigate();
  const location = useLocation();
  const collegeName = location.state?.collegeName || "";
  
  useEffect(() => {
      const initialize = async () => {
          const isValid = await isUserTokenValid();
          if (!isValid) {
            navigate('/college-login', { state: { page: "college-dashboard" } }); 
          }
          setLoading(false);
      };
      initialize();
  });

  const transformStudentData = (studentsData: Student[]) => {
    return studentsData.map((student) => ({
      id: student.email,
      name: student.name,
      ProfileUrl: student.ProfileUrl || "https://via.placeholder.com/150",
      education: `${student.degree} in ${student.department}`,
      cgpa: student.cgpa.toString(),
      highlights: student.highlights,
      year: Number(student.year),
      degree: student.degree,
      department: student.department
    }));
  };

  const fetchStudentsData = async (params: Record<string, any>, isSearch: boolean = false) => {
    try {
      setLoading(true);
      const response = await searchStudents({
        college_name: collegeName,
        ...params
      });
      const studentsData = response.data.students as Student[];
      const transformedData = transformStudentData(studentsData);
      
      if (isSearch) {
        setLastSearchResults(transformedData);  
      }
      setStudents(transformedData);
    } catch (error) {
      console.error("Failed to fetch students:", error);
      setStudents([]);
      if (isSearch) {
        setLastSearchResults([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeDashboard = async () => {
      try {
        setLoading(true);
        const response = await searchStudents({ college_name: collegeName });
        const studentsData = response.data.students as Student[];

        const uniqueOptions: FilterOptions = {
          degrees: Array.from(new Set(studentsData
            .map(student => student.degree)
            .filter((degree): degree is string => degree !== undefined))),
          departments: Array.from(new Set(studentsData
            .map(student => student.department)
            .filter((department): department is string => department !== undefined))),
          years: Array.from(new Set(studentsData
            .map(student => student.year)
            .filter((year): year is number => year !== undefined)))
        };
        setFilterOptions(uniqueOptions);
        
        const transformedStudents = transformStudentData(studentsData);
        setStudents(transformedStudents);
        setInitialStudents(transformedStudents);
      } catch (error) {
        console.error("Failed to initialize dashboard:", error);
        setStudents([]);
        setInitialStudents([]);
      } finally {
        setLoading(false);
      }
    };

    initializeDashboard();
  }, [collegeName, refreshTrigger]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (!query.trim()) {
      setStudents(initialStudents);
      setLastSearchResults([]);
      return;
    }
  };

  const handleSearchClick = async () => {
    const searchParams: Record<string, any> = {};
    
    if (searchQuery.trim()) {
      searchParams.name = searchQuery.trim();
    } else {
      if (selectedFilters.length > 0) {
        handleApplyFilters();
      } else {
        setStudents(initialStudents);
        setLastSearchResults([]);
      }
      return;
    }
  
    if (selectedFilters.length > 0) {
      const filterParams = selectedFilters.reduce((acc, filter) => {
        const categoryKey = filter.category.toLowerCase();
        if (categoryKey === 'year') {
          acc[categoryKey] = Number(filter.value);
        } else {
          if (!acc[categoryKey]) {
            acc[categoryKey] = [];
          }
          acc[categoryKey].push(String(filter.value));
        }
        return acc;
      }, {} as Record<string, any>);

      Object.keys(filterParams).forEach((key) => {
        if (Array.isArray(filterParams[key])) {
          filterParams[key] = filterParams[key].join(',');
        }
      });
      Object.assign(searchParams, filterParams);
    }
  
    await fetchStudentsData(searchParams, true);
  };

  const handleCardClick = (id: string | undefined) => {
    if (!id) return;
    const selectedStudent = students.find(student => student.id === id);
    if (selectedStudent) {
      navigate(`/college-portfolio`, { state: selectedStudent });
    }
  };

  const handleFilterSelect = (category: string, value: string | number, checked: boolean) => {
    setSelectedFilters(prev => 
      checked 
        ? [...prev, { category, value }]
        : prev.filter(filter => !(filter.category === category && filter.value === value))
    );
  };

  const isFilterSelected = (category: string, value: string | number) => {
    return selectedFilters.some(
      filter => filter.category === category && filter.value === value
    );
  };

  const handleClearAll = () => {
    setSelectedFilters([]);
    if (lastSearchResults.length > 0) {
      setStudents(lastSearchResults);
    } else {
      setStudents(initialStudents);
    }
  };

  const handleApplyFilters = async () => {
    if (selectedFilters.length === 0) {
      handleClearAll();
      return;
    }
  
    const filterParams = selectedFilters.reduce((acc, filter) => {
      const categoryKey = filter.category.toLowerCase();
      if (categoryKey === 'year') {
        acc[categoryKey] = Number(filter.value);
      } else {
        if (!acc[categoryKey]) {
          acc[categoryKey] = [];
        }
        acc[categoryKey].push(String(filter.value));
      }
      return acc;
    }, {} as Record<string, any>);
  
    Object.keys(filterParams).forEach((key) => {
      if (Array.isArray(filterParams[key])) {
        filterParams[key] = filterParams[key].join(',');
      }
    });
    if (searchQuery.trim()) {
      filterParams.name = searchQuery.trim();
    }
  
    await fetchStudentsData(filterParams);
  };
  
  return (
    <div className="h-dvh overflow-x-hidden bg-[#F5F5F5]">
      <CreateNavbar page="college-dashboard" />
      <div className="flex flex-col md:flex-row w-full px-8 md:px-16 mt-6 gap-6">
        <div className="w-full md:w-[25%] bg-white p-4 mt-2 border-r border-gray-300 rounded-md shadow-md h-[82vh] flex flex-col">
        <div className="flex-grow overflow-y-auto flex flex-col gap-4">
          <div>
            <h4 className="text-base font-semibold">Degree</h4>
            {filterOptions.degrees.map((degree) => (
              <label key={degree} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                  checked={isFilterSelected('Degree', degree)}
                  onChange={(e) => handleFilterSelect('Degree', degree, e.target.checked)}
                />
                <span>{degree}</span>
              </label>
            ))}
          </div>
          
          <div>
            <h4 className="text-base font-semibold">Department</h4>
            {filterOptions.departments.map((department) => (
              <label key={department} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                  checked={isFilterSelected('department', department)}
                  onChange={(e) => handleFilterSelect('department', department, e.target.checked)}
                />
                <span>{department}</span>
              </label>
            ))}
          </div>
          
          <div>
            <h4 className="text-base font-semibold">Year</h4>
            {filterOptions.years.map((year) => (
              <label key={year} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                  checked={isFilterSelected('Year', year)}
                  onChange={(e) => handleFilterSelect('Year', year, e.target.checked)}
                />
                <span>{year} Year</span>
              </label>
            ))}
          </div>
        </div>
          
          <div className="border-t border-gray-300 pt-4">
            <div className="flex justify-between items-center">
              <button
                className="text-gray-600 text-sm font-semibold px-4 py-2 hover:text-gray-800"
                onClick={handleClearAll}
              >
                Clear All
              </button>
              <button
                className="text-custom-blue text-sm font-semibold px-4 py-2 hover:text-blue-700"
                onClick={handleApplyFilters}
              >
                Apply All ({selectedFilters.length})
              </button>
            </div>
          </div>
        </div>
  
        <div className="w-full md:w-[75%] px-3 pb-4 h-[85vh]">
          <div className="flex flex-col md:flex-row md:items-center w-full mb-6">
            <div className="w-full md:w-[84%]">
              <div className="flex flex-grow bg-white border border-gray-300 rounded-md overflow-hidden p-2 items-center justify-center shadow-md">
                <SearchIcon className="text-gray-300 ml-4 h-6 w-6 shrink-0" />
                <div className="flex flex-grow items-center">
                  <input
                    type="text"
                    placeholder="Search Student"
                    className="flex-grow min-w-0 px-4 py-3 text-base md:text-xl focus:outline-none bg-white"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <button
                    onClick={handleSearchClick}
                    className={`bg-[#0778B6] text-white text-sm md:text-lg px-2 md:px-4 py-2 hover:bg-blue-700 transition rounded-md duration-300 mr-2 shrink-0`}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end md:ml-20 mt-4 md:mt-2">
              <img
                src={MIT_logo}
                alt="MIT Logo"
                className='w-20 h-20'
              />
            </div>
          </div>
  
          {loading ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : students.length === 0 ? (
            <div className="flex justify-center items-center h-[70vh]">
              <p className="text-gray-600 text-lg font-semibold">No student record found</p>
            </div>
          ) : (
            <div className="overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] h-[70vh] grid grid-cols-1 md:grid-cols-2 gap-6 p-4">
              {students.map((student) => (
                <div
                  key={student.id || student.email}
                  className="bg-white p-4 rounded-lg shadow-md border-t-4 border-custom-orange transition-transform transform hover:scale-105 hover:shadow-2xl flex h-[220px]"
                  onClick={() => handleCardClick(student.id || student.email)}
                >
                  <div className="flex items-center">
                    <img
                      src={student.ProfileUrl}
                      alt={student.name}
                      className="w-20 h-20 sm:w-24 sm:h-24 rounded-full border-4 border-customBlue mr-4"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = "https://via.placeholder.com/150"; 
                      }}
                    />
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800 mb-1">
                        {student.name}
                      </h3>
                      <p className="text-gray-600 text-sm mb-1">
                        <strong>Education:</strong> {student.education}
                      </p>
                      <div className="flex flex-row mb-1 ">
                        <p className="text-gray-600 text-sm  mr-[13px]">
                          <strong>Year: </strong> <span className="font-normal">{student.year}</span>
                        </p>
                        <p className="text-gray-600 text-sm  mr-[13px]">
                          <strong>CGPA: </strong> <span className="font-normal">{student.cgpa}</span>
                        </p>
                      </div>
                      <p className="text-gray-600 text-sm mb-1">
                        <strong>Highlights:</strong> {student.highlights}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollegeDashboardDesktop;

