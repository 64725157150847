import React from 'react';
import CreateNavbar from '../../Navbar/Navbar';

interface MessageCareerJourneyProps {
  message: string;
}

const JourneyInsights: React.FC<MessageCareerJourneyProps> = ({ message }) => {
  const userlevel=localStorage.getItem("userlevel");

  return (
    <><CreateNavbar page='home' />
    <div className="flex justify-center p-4 h-screen items-center">

      <div className="text-center bg-gray-100 text-gray-800 p-3 rounded-lg shadow-sm max-w-md">
        {userlevel==='Counselor' ? <p>The User's {message} Journey is not yet Created. Please request the user to create their {message} Journey.</p>:
        <p>Your {message} Journey is not yet Created. Please create your {message} Journey.</p>}
      </div>
    </div></>
  );
};

export default JourneyInsights;
