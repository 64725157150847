import React, { useEffect, useState } from "react";
import './CollegePortfolioDesktop.css';
import questionnaire from '../../../assets/img/User/Portfolio/questionnaire.svg';
import stdjourney from '../../../assets/img/User/Portfolio/stdjourney.svg';
import pcj from '../../../assets/img/User/Portfolio/pcjourney.svg';
import counselling from '../../../assets/img/User/Portfolio/counselling.svg';
import trainings from '../../../assets/img/User/Portfolio/trainings.svg';
import certificates from '../../../assets/img/User/Portfolio/certificate.svg';
import jobs from '../../../assets/img/User/Portfolio/jobs.svg';
import resume from '../../../assets/img/User/Portfolio/resume.svg';
import { useLocation, useNavigate } from "react-router-dom";
import CreateNavbar from "../../Navbar/Navbar";
import IframeComp from "../../user/portfolio/UserIframeComp";
import bckarw from '../../../assets/img/backarrowround.svg'; 
import frntarw from '../../../assets/img/frontarrowround.svg'; 
import ReactQuill from "react-quill";
import { isUserTokenValid } from "../../common/ValidateToken";
import { fetchUserDetails } from "../../../api/UserMetadata";
import { FetchPremiumJourneybyVerion } from "../../../api/FetchHtmlJourney";
import { Student } from "../CollegeDashboardDesktop";
import { addStudentNote, fetchStudentNotes, updateStudentNote } from "../../../api/College";
import SaveIcon from '@mui/icons-material/Save';

interface Option {
  id: number;
  name: string;
  logo: string;
}

interface JourneyRoute {
  id: number;
  route: string;
}
interface Note {
  notes: string;
  timeStamp?: string;
}

interface NoteResponse {
  student_email?: string;
  notes: Note[];
}

const journeyRoutes: JourneyRoute[] = [
  { id: 1, route: "/questionnaire" },
  { id: 2, route: "/myjourney" },
  { id: 3, route: "/premiumjourney" },
  //{ id: 4, route: "/booked-sessions" },
  { id: 5, route: "/comingsoon" },
  { id: 6, route: "/comingsoon" },
  { id: 7, route: "/jobs" },
  { id: 8, route: "/comingsoon" }
];

const CollegePortfolioDesktop: React.FC = () => {
  const [northStarTitle, setNorthStarTitle] = useState<string>("Loading...");  
  const [currentIndex, setCurrentIndex] = useState<number>(0); 
  const location = useLocation();
  const navigate = useNavigate();
  const [activeId,setActiveID] =useState<number>(1);
  const [noteContent, setNoteContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isExistingNote, setIsExistingNote] = useState<boolean>(false);
  const [noteTimestamp, setNoteTimestamp] = useState<string>("");
  const [iframeKey, setIframeKey] = useState<number>(0);

  const session = location.state as Student;

  let options: Option[] = [
    { id: 1, name: "Journey Builder", logo: questionnaire },
    { id: 2, name: "Standard Journey", logo: stdjourney },
    { id: 3, name: "Premium Journey", logo: pcj },
    //{ id: 4, name: "Counselling", logo: counselling },
    { id: 5, name: "Trainings", logo: trainings },
    { id: 6, name: "Certificates", logo: certificates },
    { id: 7, name: "Jobs", logo: jobs },
    { id: 8, name: "Resume Builder", logo: resume },
  ];

  const optionsPerPage = 4; 
  const totalPages = Math.ceil(options.length / optionsPerPage);


  const fetchPremiumJourney = async () => {
    try {
      const isValid = await isUserTokenValid();
      if (!isValid) {
        navigate("/login", { state: { page: "portfolio" } });
        return;
      }
      if (!session.id) {
        setNorthStarTitle("Error: Session ID is required");
        return;
      }
  
      localStorage.setItem("Email", session.id);
  
      try {
        const userDetails = await fetchUserDetails(session.id, navigate);

        const userLevel = userDetails?.userLevel;
        if (userLevel) {
          localStorage.setItem("user_status", userLevel);
        }
        const publishedJourney = userDetails?.PublishedJourney;
        const version = publishedJourney?.version;
  
        if (!version) {
          setNorthStarTitle("Your Premium Journey version is missing");
          return;
        }
  
        const response = await FetchPremiumJourneybyVerion(version);
        const northStarData = response?.career_premium_journey?.NorthStar;
  
        if (northStarData?.Title) {
          setNorthStarTitle(northStarData.Title);
        } else {
          setNorthStarTitle("Your Premium Journey is not yet created");
        }
      } catch (innerError) {
        setNorthStarTitle("Error loading Premium Journey details");
      } 
    } catch (error) {
      setNorthStarTitle("Error loading title");
    }
  };
  
  useEffect(() => {
    fetchPremiumJourney();
  }, []);


  const nextPage = () => {
    if (currentIndex < totalPages - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevPage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const fetchNotes = async () => {
    if (!session.id) return;
  
    try {
      setLoading(true);
      const response: NoteResponse = await fetchStudentNotes(session.id);
  
      if (response?.notes?.length) {
        const mostRecentNote = response.notes.sort((a, b) => (b.timeStamp ? parseInt(b.timeStamp) : 0) - (a.timeStamp ? parseInt(a.timeStamp) : 0))[0]; 
  
        setNoteContent(mostRecentNote.notes);
        setNoteTimestamp(mostRecentNote.timeStamp || ""); 
        setIsExistingNote(true);
      } else {
        setNoteContent("");
        setNoteTimestamp("");
        setIsExistingNote(false);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
      setNoteContent("");
      setNoteTimestamp("");
      setIsExistingNote(false);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchNotes();
  }, [session.id]);


  const handleNoteChange = (content: string) => {
    setNoteContent(content);
  };

  const handleSaveNote = async () => {
    if (!session.id || noteContent.trim() === "") return;
  
    try {
      const studentEmail = session.id || "";
      const noteDetails: { student_email: string; notes: { notes: string; timeStamp?: string }[] } = {
        student_email: studentEmail,
        notes: [{ notes: noteContent, ...(isExistingNote && { timeStamp: noteTimestamp }) }],
      };
  
      const response = isExistingNote
        ? await updateStudentNote(noteDetails)
        : await addStudentNote(noteDetails);
  
      if (response.success) {
        if (!isExistingNote) {
          setIsExistingNote(true);
          await fetchNotes(); 
        }
      }
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };  
  
  const visibleOptions = options.slice(currentIndex * optionsPerPage, (currentIndex + 1) * optionsPerPage);

  return (
    <div className="overflow-y-auto flex flex-col h-screen">
      <CreateNavbar page="college-dashboard" />
      <div className="flex pl-10 mt-1 pr-10 flex-row">
        <div className="flex flex-col">
          <div className="flex flex-col ml-[5px] border mt-2 custombox_college">
            <div className="flex profilebox_college flex-row">
              <div className="flex mt-[20px] flex-row">
              <img
                src={session.ProfileUrl}
                alt={session.name}
                className="w-20 h-20 rounded-full border-4 border-customBlue mx-2"
              />
                <div className="mt-2 ml-2 mr-2 max-w-[170px] customname_college">{session.name}</div>
                
              </div>
              <div className="partition-line_college"></div>
              <div className="info mt-4 relative">
                {localStorage.getItem('user_status') === 'Standard' ? (
                    <img src={stdjourney} className="absolute top-0 right-2 northstar mt-[-16px]" alt="" /> 
                  ) : 
                    <img src={pcj} className="absolute top-0 right-2 northstar mt-[-16px]" alt="" />
                  }
                <div className="mb-1">
                  <p className="text-gray-600 text-base">
                    <strong>Education:</strong> {session.education}
                  </p>
                  <div className="flex flex-row  ">
                    <p className="text-gray-600 text-sm sm:text-base mr-[13px]">
                      <strong>Year: </strong> <span className="font-normal">{session.year}</span>
                    </p>
                    <p className="text-gray-600 text-sm sm:text-base mr-[13px]">
                      <strong>CGPA: </strong> <span className="font-normal">{session.cgpa}</span>
                    </p>
                  </div>
                </div>
                <div className="text-gray-600 text-base mb-1">
                  <strong>Highlights:</strong> {session.highlights}
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center pt-10  flex-col h-full">
              <div className="relative flex flex-row">
                {currentIndex > 0 && (
                  <button
                    onClick={prevPage}
                    className="absolute left-[-30px] top-12 cursor-pointer text-[#0778B6] hover:text-gray-700"
                  >
                    <img src={bckarw} alt="Back" className="w-[24px] h-[24px]" />
                  </button>
                )}

                <div className="flex flex-row justify-evenly w-full">
                  {visibleOptions.map((option) => (
                    <div
                      key={option.id}
                      className={`mx-3 optionbox customActionBox_college ${
                        activeId === option.id ? 'bg-[#ECFAFD]' : ''
                      }`}
                      onClick={() => setActiveID(option.id)}
                    >
                      <img
                        src={option.logo}
                        alt={option.name}
                        className="ml-10 boxicon_desktop_college mt-5 mb-2"
                      />
                      <span className="optionboxtextDesk_college text-wrap">{option.name}</span>
                    </div>
                  ))}
                </div>

                {currentIndex < totalPages - 1 && (
                  <button
                    onClick={nextPage}
                    className="absolute right-[-30px] top-12 cursor-pointer text-[#0778B6] hover:text-gray-700"
                  >
                    <img src={frntarw} alt="Next" className="w-[24px] h-[24px]" />
                  </button>
                )}
              </div>

              <div className="flex-grow w-full max-w-[var(--common-width)] md:max-w-[var(--medium-width)] lg:max-w-[var(--large-width)] min-h-[200px] my-5 overflow-hidden">
                <div className="border-t-2 px-3 h-full flex flex-col overflow-auto">
                  <div className="flex items-center justify-between py-2 ml-2">
                    <h2 className="text-lg font-semibold text-[#0778B6]">
                      Notes
                    </h2>
                    <div
                      className="flex justify-end mr-2 cursor-pointer"
                      onClick={handleSaveNote}
                    >
                      <SaveIcon className="text-[#0778B6]" />
                    </div>
                  </div>
                  <ReactQuill
                    value={noteContent}
                    onChange={handleNoteChange}
                    className="h-[240px] w-full"
                    placeholder="Enter your notes here..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="customviewbox_college border mr-2 mt-2 ml-5">
          <div className="overflow-y-auto flex flex-col justify-between py-4">
            <IframeComp
              className={"iframecomp w-full min-h-[560px] overflow-y-auto"}
              endpoint={journeyRoutes.find((journey) => journey.id === activeId)?.route}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegePortfolioDesktop;
