// CertificateWrapper.tsx
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import HRLoginDesktop from '../components/HR/Login/HRLogin';
import HRLoginMobile from '../components/HR/Login/HRLoginMobile';

const HRLoginMainPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className="flex flex-col">
      {/* <CreateNavbar page='home' /> */}
      {isMobile ? (
        <HRLoginMobile />
      ) : (
        <HRLoginDesktop  />
      )}
    </div>
  );
};

export default HRLoginMainPage;
