import React, { useState, useEffect } from 'react'
import JobCard, { Job } from './JobCard'
import Pagination from './Pagination'
import { BackendApi } from '../../api/apiConfig/ApiFormatter'
import LoadingSpinner from '../common/LoadingSpinner'

export interface ApiResponse {
  message: string
  data: {
    jobs: Job[]
    company: string[]
    total_pages: number
    total_records: number
  }
}

interface JobListProps {
  searchTerms: {
    jobTitle?: string
    location?: string
    role?: string
  }
  companyFilters: string[]
}

const JobList: React.FC<JobListProps> = ({ searchTerms, companyFilters }) => {
  const [jobs, setJobs] = useState<Job[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(25)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [noJobsFound, setNoJobsFound] = useState(false)

  // Fetch jobs from API
  const fetchJobs = async (
    page: number,
    pageSize: number,
    searchTerms: JobListProps['searchTerms'],
    companyFilters: string[]
  ) => {
    setIsLoading(true)
    try {
      const baseUrl = `${BackendApi()}jobs/search`
      const searchParams = new URLSearchParams()

      // Add search terms to URL params
      if (searchTerms.jobTitle)
        searchParams.append('job_title', searchTerms.jobTitle)
      if (searchTerms.location)
        searchParams.append('location', searchTerms.location)
      if (searchTerms.role) searchParams.append('job_role', searchTerms.role)

      // Add company filters 
      if (companyFilters.length > 0) {
        searchParams.append('company', companyFilters.join(','))
      }

      searchParams.append('page', page.toString())
      searchParams.append('page_size', pageSize.toString())

      const url = `${baseUrl}?${searchParams.toString()}`
      const response = await fetch(url)
      const data: ApiResponse = await response.json()

      setJobs(data.data.jobs)
      setTotalRecords(data.data.total_records)
      setNoJobsFound(data.data.jobs.length === 0)
    } catch (error) {
      console.error('Error fetching jobs:', error)
      setNoJobsFound(true)
    } finally {
      setIsLoading(false)
    }
  }

  // Fetch jobs on initial load, page change, or when search/filter updates
  useEffect(() => {
    fetchJobs(currentPage, recordsPerPage, searchTerms, companyFilters)
  }, [currentPage, recordsPerPage, searchTerms, companyFilters])

  // Reset to page 1 when search terms or filters change
  useEffect(() => {
    setCurrentPage(1)
  }, [searchTerms, companyFilters])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className="flex flex-col relative min-h-screen">
      {noJobsFound ? (
        <div className="flex justify-center items-center h-full">
          <p className="text-gray-500 text-lg">
            No jobs found for given search criteria
          </p>
        </div>
      ) : (
        <div className="flex flex-col gap-6 mb-20">
          {jobs.map((job, index) => (
            <JobCard key={`${job.company}-${job.job_id}-${index}`} job={job} />
          ))}
        </div>
      )}

      {!noJobsFound && (
        <Pagination
          currentPage={currentPage}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onPageChange={setCurrentPage}
          onRecordsPerPageChange={(newRecords) => {
            setRecordsPerPage(newRecords)
            setCurrentPage(1)
          }}
        />
      )}
    </div>
  )
}

export default JobList
