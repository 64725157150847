import component from '../../../assets/img/premiumJourney/component.png'
import frame2 from '../../../assets/img/premiumJourney/tick.svg'
import LaunchIcon from '@mui/icons-material/Launch';
import frame3 from '../../../assets/img/premiumJourney/image 8.svg';
import ReactMarkdown from 'react-markdown';

interface YearWithQuotersComp {
    year: any;
    premiumJourney: any;
    i: number;
    isValid: (history: string) => boolean;
}

const YearWithQuoters: React.FC<YearWithQuotersComp> = ({ year, premiumJourney, i, isValid }) => {
    return (
        <div key={i} id={`year${i + 1}`}>
            {year.Quarters.map((Quarter: any, j: number) => {
                return (
                    <div key={j} id={`quarter${j + 1}`}>
                        <div className="year1-quarter1 my-5">
                            <div className="bg-[#0778B6] rounded-lg px-4 py-4">
                                <div className="flex">
                                    <img className="w-6 h-6 desk-icon" src={component} alt="icon" />
                                    <img className="w-7 h-7 mob-icon" src={component} alt="icon" />
                                    <p className="text-xl text-white font-semibold pl-3">
                                        Year {i + 1}: Quarter {j + 1}
                                    </p>
                                </div>
                            </div>
                            <div className="content">
                                {Quarter[`Q${j + 1}_Goals`]?.length > 0 &&
                                    Array.isArray(Quarter[`Q${j + 1}_Goals`]) && (
                                        <div>
                                            <p className="text-base mb-1 font-semibold pt-3 pl-3 lg:pl-4 sm:pl-2 text-[#0778b6]">Goals</p>
                                            {Quarter[`Q${j + 1}_Goals`]
                                                .map((goal: string, index: number) => (
                                                    <>
                                                        {isValid(goal) && <div key={index} className="flex flex-row px-4 md:px-6 gap-2">
                                                            <div className='font-semibold'>{index + 1}.</div>
                                                            <ReactMarkdown>{goal}</ReactMarkdown>
                                                        </div>}
                                                    </>
                                                ))}
                                        </div>
                                    )}

                                {(Quarter[`Q${j + 1}_ActionPlan`]?.Courses?.length > 0 ||
                                    Quarter[`Q${j + 1}_ActionPlan`]?.Networking?.length > 0 ||
                                    Quarter[`Q${j + 1}_ActionPlan`]?.Certifications?.length > 0 ||
                                    Quarter[`Q${j + 1}_ActionPlan`]?.Job?.length > 0) &&
                                    (Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Courses) ||
                                        Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Networking) ||
                                        Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Certifications) ||
                                        Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Job))
                                    && (
                                        <div>
                                            <p className="text-base mb-1 font-semibold pt-3 pl-3 lg:pl-4 sm:pl-2 text-[#0778b6]">Plan</p>
                                            <ul className="list-disc pl-8 md:pl-10 sm:pr-2">
                                                {Quarter[`Q${j + 1}_ActionPlan`]?.Courses?.length > 0 &&
                                                Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Courses) &&
                                                    Quarter[`Q${j + 1}_ActionPlan`].Courses.map((course: any, id: number) => (
                                                        <>
                                                            {isValid(course.description) &&
                                                                <li key={id} className="text-base font-normal leading-6">
                                                                    <ReactMarkdown>{course.description}</ReactMarkdown>{' '}
                                                                    {course.url && <a
                                                                        href={course.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="text-blue-600"
                                                                    >
                                                                        <div> Click here <LaunchIcon style={{ fontSize: '0.875rem' }} /></div>
                                                                    </a>
                                                                    }
                                                                </li>}
                                                        </>
                                                    ))}

                                                {Quarter[`Q${j + 1}_ActionPlan`]?.Networking?.length > 0 &&
                                                Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Networking) &&
                                                    Quarter[`Q${j + 1}_ActionPlan`].Networking.map((Network: any, id: number) => (
                                                        <>
                                                            {isValid(Network.description) &&
                                                                <li key={id} className="text-base font-normal leading-6">
                                                                    <ReactMarkdown>{Network.description}</ReactMarkdown>{' '}
                                                                    {Network.url && <a
                                                                        href={Network.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="text-blue-600"
                                                                    >
                                                                        <div>Click here <LaunchIcon style={{ fontSize: '0.875rem' }} /></div>
                                                                    </a>}
                                                                </li>}
                                                        </>
                                                    ))}

                                                {Quarter[`Q${j + 1}_ActionPlan`]?.Certifications?.length > 0 &&
                                                Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Certifications) &&
                                                    Quarter[`Q${j + 1}_ActionPlan`].Certifications.map((Certificate: any, id: number) => (
                                                        <>
                                                            {isValid(Certificate.description) &&
                                                                <li key={id} className="text-base font-normal leading-6">
                                                                    <ReactMarkdown>{Certificate.description}</ReactMarkdown>{' '}
                                                                    {Certificate.url && <a
                                                                        href={Certificate.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="text-blue-600"
                                                                    >
                                                                        <div>Click here <LaunchIcon style={{ fontSize: '0.875rem' }} /></div>
                                                                    </a>}
                                                                </li>}
                                                        </>
                                                    ))}

                                                {Quarter[`Q${j + 1}_ActionPlan`]?.Job?.length > 0 &&
                                                Array.isArray(Quarter[`Q${j + 1}_ActionPlan`]?.Job) &&
                                                    Quarter[`Q${j + 1}_ActionPlan`].Job.map((actionable: any, id: number) => (
                                                        <li key={id} className="text-base font-normal leading-6">
                                                            {typeof actionable === "string" ? (
                                                                actionable
                                                            ) : (
                                                                <>
                                                                    <ReactMarkdown>{actionable.description}</ReactMarkdown>{' '}
                                                                    {actionable.url && (
                                                                        <a
                                                                            href={actionable.url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="text-blue-600"
                                                                        >
                                                                            <div>Click here
                                                                                <LaunchIcon style={{ fontSize: '0.875rem' }} /></div>
                                                                        </a>
                                                                    )}
                                                                </>
                                                            )}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                );
            })}
            {year.Y1_ExpectedOutcomes.length > 0 &&
            Array.isArray(year.Y1_ExpectedOutcomes) && (
                <div className="bg-custom rounded-lg mt-6 p-3 md:p-4">
                    <p className="text-base font-semibold pb-1">Expected Outcomes: </p>
                    <ul className="list-disc pl-5">
                        {year.Y1_ExpectedOutcomes.map((actionable: string, id: number) => (
                            <>
                                {isValid(actionable) &&
                                    <li key={id} className="text-base font-normal leading-6">
                                        <ReactMarkdown>{actionable}</ReactMarkdown>
                                    </li>}
                            </>
                        ))}
                    </ul>
                </div>
            )}
            {isValid((i === premiumJourney.Milestones.length - 1)
                ? year.Y6to10_AlignmentWithNorthStar
                : year[`Y${i + 1}_AlignmentWithNorthStar`]) &&
                <div className="bg-[#BCE7F3] flex flex-col-2 my-5 rounded-lg">
                    <div className=" flex gap-2 flex-col tab-custom md:w-4/5 py-5 pl-4 md:pl-4">
                        <div className="flex flex-row gap-2 ">
                            <img className="size-5" src={frame2} alt="user" />
                            <p className="text-semibold text-sm year-color">
                            {`Year ${i + 1}`}
                            </p>
                        </div>
                        <p className=" text-xl font-semibold">Keep it up</p>
                        <p className="text-sm md:text-base font-normal">
                        <ReactMarkdown> 
                            {i === premiumJourney.Milestones.length - 1 ? year.Y6to10_AlignmentWithNorthStar : year[`Y${i + 1}_AlignmentWithNorthStar`]}
                        </ReactMarkdown>
                        </p>
                    </div>
                    <div className='flex justify-end items-start md:w-1/5 '>
                        <img src={frame3} className='' alt='troffy' />
                    </div>
                </div>}
        </div>
    );
};

export default YearWithQuoters;
