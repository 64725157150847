import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';
import { fetchCounsellorBookings, fetchAndUpdateBookings } from '../../api/Counsellor';
import { isUserTokenValid } from '../common/ValidateToken';
import clientIcon from '../../assets/img/placeholder-user.jpg';
import LoadingSpinner from '../common/LoadingSpinner';
import NoSessions from '../CounsellorUser/NoSessions';

export interface UserInfo {
  email: string;
  name: string | null;
  profileIcon: string;
}

interface Session {
  id: string;
  clientName: string;
  counselor: {
    name: string;
    email: string;
    contact: number;
    profileUrl: string;
    bio: string;
    address: string;
    specialty?: string;
    rating: number;
  };
  feedback?: string;
  draft_feedback?: string;
  user_info: UserInfo;
  date: string;
  time: string;
  timestamp: any;
   status: 'Upcoming' | 'Completed' | 'Cancelled' | 'Updated' | 'Awaiting_feedback' | 'Expired' | 'Updated_draft';
  meetingUrl: string;
}

const CounselorBookedSessionsPage: React.FC = () => {
  const navigate = useNavigate();
 const [filter, setFilter] = useState<'All' | 'Upcoming' | 'Completed' | 'Cancelled' | 'Updated' | 'Updated_draft' | 'Awaiting_feedback' | 'Expired'>('All');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [bookedSessions, setBookedSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const statusOrder: Record<string, number> = {
  Upcoming: 1,
  'Awaiting feedback': 2,
  Updated: 3,
  'Updated Draft': 4,
  Completed: 5,
  Expired: 6,
  Cancelled: 7,
};

  const getStatusDisplayName = (status: string): string => {
    switch (status) {
      case 'Updated_draft':
        return 'Review in process'
      case 'Updated':
        return 'Updated'
      case 'Awaiting_feedback':
        return 'Awaiting feedback'
      default:
        return status
    }
  }

  const normalizedStatus = (status: string): string => {
    if (status === 'Awaiting_Feedback') return 'Awaiting feedback'
    if (status === 'Updated_draft') return 'Updated Draft'
    return status
  }


 const sortSessions = (sessions: Session[]) => {
  return [...sessions].sort((a, b) => {
    const statusA = statusOrder[normalizedStatus(a.status)] || 999
    const statusB = statusOrder[normalizedStatus(b.status)] || 999

    if (statusA !== statusB) {
      return statusA - statusB
    }

    // Parse date in DD/MM/YYYY format
    const parseDate = (dateStr: string) => {
      const [day, month, year] = dateStr.split('/').map(Number);
      return new Date(year, month - 1, day);
    };

    // Parse time in 12-hour format
    const parseTime = (timeStr: string) => {
      const [time, period] = timeStr.split(' ');
      let [hours, minutes] = time.split(':').map(Number);
      
      if (period === 'PM' && hours !== 12) {
        hours += 12;
      }
      if (period === 'AM' && hours === 12) {
        hours = 0;
      }
      
      return { hours, minutes };
    };

    const dateA = parseDate(a.date);
    const dateB = parseDate(b.date);

    // Compare dates first
    if (dateA.getTime() !== dateB.getTime()) {
      return dateA.getTime() - dateB.getTime();
    }

    // If dates are the same, compare times
    const timeA = parseTime(a.time);
    const timeB = parseTime(b.time);

    // Compare hours first
    if (timeA.hours !== timeB.hours) {
      return timeA.hours - timeB.hours;
    }

    // If hours are the same, compare minutes
    return timeA.minutes - timeB.minutes;
  });
};


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        setLoading(true);
        const isValid = await isUserTokenValid();
        if (!isValid) {
          navigate('/login');
          return;
        }

        // Retrieve counselor email from localStorage
        const counsellorEmail = localStorage.getItem('Email');
        if (!counsellorEmail) {
          console.error('Counselor email not found in local storage.');
          return;
        }

        // Step 1: Fetch updated statuses using the fetchAndUpdateBookings API
        await fetchAndUpdateBookings('', counsellorEmail);

         // Step 2: Fetch initial session data
        const response = await fetchCounsellorBookings();
        const sessions = response.bookings.map((booking: any): Session => ({
            id: booking.meeting_id,
            clientName: booking.user_email,
            counselor: {
              name: booking.counsellor_info.name || 'Unknown',
              email: booking.counsellor_info.email || '',
              contact: booking.counsellor_info.contact || 0,
              profileUrl: clientIcon,
              bio: booking.counsellor_info.bio || '',
              address: booking.counsellor_info.address || '',
              specialty: booking.counsellor_info.specialty || '',
              rating: booking.counsellor_info.rating || 0.0,
            },
            feedback: booking.feedback,
            draft_feedback: booking.draft_feedback,
            user_info: {
              email: booking.user_info?.email || booking.user_email,
              name: booking.user_info?.name,
              profileIcon: booking.user_info?.profileIcon || clientIcon,
            },
            date: booking.date,
            time: booking.time,
            timestamp: booking.timestamp,
            status: booking.status,
            meetingUrl: booking.meeting_url,
          }));

        const sortedSessions = sortSessions(sessions)
        setBookedSessions(sortedSessions)

      } catch (error: any) {
        console.error('Error fetching sessions:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const getUniqueStatuses = (sessions: Session[]): Session['status'][] => {
    return sessions
      .reduce((acc: Session['status'][], session) => {
        if (!acc.includes(session.status)) {
          acc.push(session.status)
        }
        return acc
      }, [])
      .sort((a, b) => (statusOrder[a] || 999) - (statusOrder[b] || 999))
  }

  const filters = getUniqueStatuses(bookedSessions)

  const filteredSessions = bookedSessions.filter(session =>
    (filter === 'All' || session.status === filter) &&
    (session.user_info.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
     session.clientName.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className='h-dvh overflow-x-hidden'>
      <CreateNavbar page='home' />
      <div className="bg-gray-100 p-4 md:p-6 lg:p-8 font-sans">
        {bookedSessions.length === 0 ? (
          <NoSessions handleBookClick={() => navigate('/counselling')} />
        ) : (
          <>
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-center mb-6 md:mb-8 lg:mb-10 text-gray-900">Your Booked Sessions</h1>
            <div className="mb-4 flex justify-between items-center">
              <input
                type="text"
                placeholder="Search by Username"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="max-w-xs px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-custom-orange focus:border-custom-orange text-sm md:text-base"
              />
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value as 'All' | 'Upcoming' | 'Completed' | 'Cancelled'  | 'Updated' | 'Updated_draft' | 'Awaiting_feedback' | 'Expired')}
                className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange text-sm md:text-base"
              >
                <option value="All">All Sessions</option>
                {filters.map((status) => (
                  <option key={status} value={status}>
                    {getStatusDisplayName(status)}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {filteredSessions.map((session) => (
                <div
                  key={session.id}
                  className="bg-white p-3 rounded-lg shadow-md border-t-4 border-custom-orange transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col"
                  >
                    <div className="flex items-center mb-3 sm:mb-4">
                    <img
                      src={session.user_info.profileIcon}
                      alt={session.clientName}
                        className="w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 rounded-full border-4 border-blue-500 mr-3"
                      />
                      <div>
                        <h3 className="text-lg sm:text-xl lg:text-lg font-semibold text-gray-800 mb-1 sm:mb-2 w-48 truncate">
                          {session.user_info.name || session.clientName}
                        </h3>
                        <p className="text-gray-600 text-xs sm:text-sm mb-1"><strong>Date:</strong> {session.date}</p>
                        <p className="text-gray-600 text-xs sm:text-sm mb-1"><strong>Time:</strong> {session.time}</p>
                        <p className={`text-gray-600 text-xs sm:text-sm ${session.status === 'Completed' ? 'text-green-500' : session.status === 'Cancelled' ? 'text-red-500' : session.status === 'Expired' ? 'text-gray-500' : session.status === 'Awaiting_feedback' ? 'text-orange-500' : 'text-yellow-500'}`}>
                        <strong>Status:</strong>{' '}
                        {getStatusDisplayName(session.status)}
                      </p>
                    </div>
                  </div>
                  <div className="flex-grow flex items-end">
                    <button
                      onClick={() => navigate(`/counsellor/userPortfolio`, { state: { session } })}
                        disabled={session.status === 'Cancelled' }
                        className={`w-full px-3 py-1 font-semibold rounded-lg shadow-md transition-colors duration-300 ${session.status === 'Cancelled' ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CounselorBookedSessionsPage;
