import React from 'react'
import { useMediaQuery } from 'react-responsive'

interface PaginationProps {
  currentPage: number
  totalRecords: number
  recordsPerPage: number
  onPageChange: (page: number) => void
  onRecordsPerPageChange: (recordsPerPage: number) => void
  className?: string; 
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage, totalRecords, recordsPerPage, onPageChange, onRecordsPerPageChange,className,
}) => {
  const totalPages = Math.ceil(totalRecords / recordsPerPage)
  const startRecord = (currentPage - 1) * recordsPerPage + 1
  const endRecord = Math.min(currentPage * recordsPerPage, totalRecords)

  const handlePageChange = (page: number) => {
    onPageChange(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleRecordsPerPageChange = (newRecords: number) => {
    onRecordsPerPageChange(newRecords)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const getPageNumbers = () => {
    const pages = []
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) pages.push(i)
    } else if (currentPage <= 3) {
      pages.push(1, 2, 3, 4, '...', totalPages)
    } else if (currentPage >= totalPages - 2) {
      pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages)
    } else {
      pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages)
    }
    return pages
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <div className={`${isMobile? 'bg-white p-2 border-t border-gray-200 w-full right-0': `fixed bottom-0 bg-white p-2 border-t border-gray-200 ${className || 'right-0 w-4/5'}`}`}>
      <div className={`flex items-center justify-end gap-8 ${isMobile ? 'flex-col items-center' : 'mr-4 '}`}>
       {!isMobile && (
        <>
          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-600">Rows per page:</span>
            <select value={recordsPerPage} onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))} className="px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
              {[25, 50, 75, 100].map((value) => <option key={value} value={value}>{value}</option>)}
            </select>
          </div>
          <span className="text-sm text-gray-600">Results : {startRecord} to {endRecord} of {totalRecords} </span>
          <div className="flex items-center gap-2">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="p-1 text-gray-600 hover:bg-gray-100 rounded-md disabled:opacity-50 disabled:cursor-not-allowed">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </button>
            {getPageNumbers().map((pageNumber, index) => (
              <button key={index} onClick={() => typeof pageNumber === 'number' ? handlePageChange(pageNumber) : undefined} disabled={pageNumber === '...'} className={`px-3 py-1 text-sm rounded-md ${pageNumber === currentPage ? 'bg-blue-500 text-white' : pageNumber === '...' ? 'text-gray-600 cursor-default' : 'text-gray-600 hover:bg-gray-100'}`}>
                {pageNumber}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="p-1 text-gray-600 hover:bg-gray-100 rounded-md disabled:opacity-50 disabled:cursor-not-allowed">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className="flex px-3 items-center justify-between w-full">
            <span className="text-sm text-gray-600">Results : {startRecord} - {endRecord} of {totalRecords}</span>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Records:</span>
              <select value={recordsPerPage} onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))} className="px-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
                {[25, 50, 75, 100].map((value) => <option key={value} value={value}>{value}</option>)}
              </select>
            </div>
          </div>
          <div className="flex items-center gap-1 w-full justify-center -mt-7">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="p-2 text-gray-600 hover:bg-gray-100 rounded-md disabled:opacity-50 disabled:cursor-not-allowed">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </button>
            {getPageNumbers().map((pageNumber, index) => (
              <button key={index} onClick={() => typeof pageNumber === 'number' ? handlePageChange(pageNumber) : undefined} disabled={pageNumber === '...'} className={`px-3 py-1 text-sm rounded-md ${pageNumber === currentPage ? 'bg-blue-500 text-white' : pageNumber === '...' ? 'text-gray-600 cursor-default' : 'text-gray-600 hover:bg-gray-100'}`}>
                {pageNumber}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="p-2 text-gray-600 hover:bg-gray-100 rounded-md disabled:opacity-50 disabled:cursor-not-allowed">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
    </div>
  )
}

export default Pagination