import { useRef, useState } from "react";
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';


export const ResumeSelect: React.FC<any> = ({ selectedFile, setSelectedFile, setbypassResumeCheck }) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

   const handleClick = (event: any) => {
     event.preventDefault()
     if (hiddenFileInput.current) {
       hiddenFileInput.current.value = ''
     }
     hiddenFileInput.current?.click()
   }

const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded && fileUploaded.type !== "application/pdf") {
            alert("You are trying to upload an unsupported file format. Please upload PDF files only.")
            setSelectedFile(null);
        } else {
            setSelectedFile(fileUploaded);
        }
    };

  const formatFileName = (fileName: string) => {
    if (fileName.length <= 50) return fileName
    const lastDotIndex = fileName.lastIndexOf('.')
    const name = fileName.substring(0, lastDotIndex)
    const extension = fileName.substring(lastDotIndex)
    return name.substring(0, 50) + '...' + extension
  }

  return (
  <div className="relative py-10">
    <div className="flex flex-row gap-2 px-10 py-5 bg-file-upload-blue-blur w-full rounded-xl border-1 border-file-upload-outer relative">
      <div className="flex justify-center items-center">
        <button 
          className="p-2 text-back-prev-text border-2 border-file-upload h-11 w-32 rounded-md text-sm sm:text-base" 
          onClick={handleClick}
        >
          BROWSE
        </button>
        <input 
          type="file" 
          onChange={handleChange} 
          ref={hiddenFileInput} 
          style={{ display: 'none' }} 
        />
      </div>
      <div className="flex-grow overflow-hidden min-w-0">
        <div className="font-medium text-sm sm:text-lg truncate">
          {selectedFile !== null && selectedFile !== undefined ? 'Selected File: ' : 'Select Your Resume to Upload'}
        </div>
        {selectedFile !== null && selectedFile !== undefined ? (
          <div className="text-inherit break-words w-full">
            {formatFileName(selectedFile.name)}
          </div>
        ) : (
          <div className="block text-sm sm:text-base text-description-text w-fit">
            Supported File Format: PDF
          </div>
        )}
      </div>
      {selectedFile && (
        <div 
          onClick={() => setSelectedFile(null)} 
          className="absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
        >
          <ClearTwoToneIcon style={{ fontSize: 18 }} />
        </div>
      )}
    </div>
  </div>
)};
        