import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CollegeLoginDesktop from '../components/College/CollegeLoginDesktop';
import CollegeLoginMobile from '../components/College/CollegeLoginMobile';




const CollegeLoginMainPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className="flex flex-col">
      {/* <CreateNavbar page='home' /> */}
      {isMobile ? (
        <CollegeLoginMobile />
      ) : (
        <CollegeLoginDesktop  />
      )}
    </div>
  );
};

export default CollegeLoginMainPage;
