import React, { useEffect, useState } from "react";
import CreateNavbar from "../../../Navbar/Navbar";
import './PortfolioDesktop.css';
import northstar from '../../../../assets/img/User/Portfolio/northstar.svg';
import questionnaire from '../../../../assets/img/User/Portfolio/questionnaire.svg';
import stdjourney from '../../../../assets/img/User/Portfolio/stdjourney.svg';
import pcj from '../../../../assets/img/User/Portfolio/pcjourney.svg';
import counselling from '../../../../assets/img/User/Portfolio/counselling.svg';
import trainings from '../../../../assets/img/User/Portfolio/trainings.svg';
import certificates from '../../../../assets/img/User/Portfolio/certificate.svg';
import jobs from '../../../../assets/img/User/Portfolio/jobs.svg';
import resume from '../../../../assets/img/User/Portfolio/resume.svg';
import { useNavigate } from "react-router-dom";
import PortfolioDeskView from "./PortfolioDeskView";
import IframeComp from "../UserIframeComp";
import LaunchIcon from '@mui/icons-material/Launch';
import { FetchPremiumJourneybyVerion } from "../../../../api/FetchHtmlJourney";
import { isUserTokenValid } from "../../../common/ValidateToken";
import { fetchUserDetails } from "../../../../api/UserMetadata";

interface Option {
  id: number;
  name: string;
  logo: string;
}

interface JourneyRoute {
  id: number;
  route: string;
  externalUrl?: string
}

// Define the journey routes with type annotations
const journeyRoutes: JourneyRoute[] = [
  { id: 1, route: "/questionnaire" },
  { id: 2, route: "/myjourney" },
  { id: 3, route: "/premiumjourney" },
  { id: 4, route: "/booked-sessions" },
  { id: 5, route: "/enrolledcourses", externalUrl: "https://trainings.skilljourney.in/t/u/activeCourses" },
  { id: 6, route: "/comingsoon" },
  { id: 7, route: "/jobs" },
  { id: 8, route: "/comingsoon" }
];

const PortfolioDesktop: React.FC = () => {
  const [isNorthStart,setNorthStartStatus]=useState<boolean>(true);
  const [northStarTitle, setNorthStarTitle] = useState<string>("Loading...");
  const [id,setId]=useState<number>(0);
  const navigate = useNavigate();
  const profileIcon:any =localStorage.getItem('profileIcon')
  const name:any=localStorage.getItem('Name')
  const [activeId,setActiveID] =useState<number>(1);
  const [iframeKey, setIframeKey] = useState<number>(0);
  let options: Option[] = [
    { id: 1, name: "Journey Builder", logo: questionnaire },
    { id: 2, name: "Standard Journey", logo: stdjourney },
    { id: 3, name: "Premium Journey", logo: pcj },
    { id: 4, name: "Counselling", logo: counselling },
    { id: 5, name: "Trainings", logo: trainings },
    { id: 6, name: "Certificates", logo: certificates },
    { id: 7, name: "Jobs", logo: jobs },
    { id: 8, name: "Resume Builder", logo: resume },
  ];
  const options1=options.slice(0, 4);
  const options2=options.slice(4);

  const fetchPremiumJourney = async () => {
      try {
        const isValid = await isUserTokenValid();
        if (!isValid) {
          navigate('/login',{ state:{page:"portfolio"} });
          return;
        }
    
        const username = localStorage.getItem("Email") || ""; 
        const userDetails = await fetchUserDetails(username, navigate);
    
        const publishedJourney = userDetails?.PublishedJourney;
        const version = publishedJourney?.version;
      
        const response = await FetchPremiumJourneybyVerion(version);
        const northStarData = response?.career_premium_journey?.NorthStar;
    
        if (northStarData?.Title) {
          localStorage.setItem("northstarTitle", northStarData.Title);
          setNorthStarTitle(northStarData.Title);
        } else {
          setNorthStarTitle("Your Premium Journey is not yet created");
        }
      } catch (error) {
        console.error("Error fetching premium journey:", error);
        setNorthStarTitle("Error loading title");
      }
    };
    
  // useEffect to call API on component mount
  useEffect(() => {
    fetchPremiumJourney();
  }, []);

  const handleNavigate = (id: number): string | undefined => {
    const selectedRoute = journeyRoutes.find((journey) => journey.id === id)
    if (selectedRoute?.externalUrl) {
    const LMSTOKEN = localStorage.getItem('LMSToken');
    return LMSTOKEN ? `${selectedRoute.externalUrl}?ssoToken=${LMSTOKEN}` : selectedRoute.externalUrl;
  }
  return selectedRoute?.route;
  }

  const handleOptionClick = (optionId: number) => {
    setActiveID(optionId);
    setIframeKey(prev => prev + 1); 
  };
  
  return (
    <div className="overflow-y-auto flex flex-col h-screen">
      <CreateNavbar page="home" />
      <div className="flex pl-10 mt-1 pr-10 flex-row">
        <div className="flex flex-col">
          <div className="flex flex-col ml-[5px] border mt-2 custombox">
            <div className="flex profilebox flex-row min-h-fit">
              <div className="flex mt-[30px] flex-row">
                <img
                  src={profileIcon}
                  alt="Profile"
                  className="w-[60px] h-[60px] ml-[18px]  rounded-full"
                />
                <div className="mt-1 ml-2 mr-2 max-w-[150px] overflow-hidden customname"><div className="break-words line-clamp-2" title={name && name.length > 22 ? name : ""}>{name}</div></div>
                {localStorage.getItem('userLevel') === 'Standard' ? (
                  <img src={stdjourney} className="mt-[-16px] northstar" alt="" /> 
                ) : 
                  <img src={pcj} className="mt-[-16px] northstar" alt="" />
                }
            </div>
            <label className="flex ml-[-30px] customlinePortfolio mt-[60px]"></label>
              {
                isNorthStart ? (
                  <div className="flex flex-col  mt-[10px] ml-[-20px] ">
                    <div className="flex w-full flex-row items-center">
                      <img
                        src={northstar}
                        alt="NorthStar"
                        className=" w-[40px] h-[40px]"
                      />
                      <span className="northstartext ">NorthStar</span>
                    </div>
                    <div className="flex ml-[15px]  userNorthStar">
                    {northStarTitle}
                    </div>
                  </div>
                ):(
                  <div className="flex flex-col northstartbox mt-[10px] ml-[-20px]">
                    <div className="flex w-full flex-row items-center">
                      <img
                        src={northstar}
                        alt="NorthStar"
                        className=" w-[30px] h-[30px]"
                      />
                      <span className="northstartext ">Lorem</span>
                    </div>
                    <div className="flex ml-[15px] northstardynamictext ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididun
                    </div>
                  </div>
                )
              }
            </div>
            <div className="flex justify-center items-center p-10 flex-col">
              <div className="flex flex-row">
                {options1.map((option) => (
                  <div key={option.id} className={`ml-7 optionbox customActionBox ${activeId ==option.id ? 'bg-[#ECFAFD]':'' }`} onClick={() => handleOptionClick(option.id)} >
                    <img
                      src={option.logo}
                      alt={option.name}
                      className="ml-10 boxicon_desktop mt-5 mb-2"
                    />
                    <span className="optionboxtextDesk p-2 text-wrap">{option.name}</span>
                  </div>
                ))}
              </div>
              <div className="pt-[50px]" />
              <div className="flex flex-row">
                {options2.map((option) => (
                  <div key={option.id} className={`ml-7 optionbox customActionBox ${activeId ==option.id ? 'bg-[#ECFAFD]':'' }`} onClick={() => handleOptionClick(option.id)} >
                    <img
                      src={option.logo}
                      alt={option.name}
                      className="boxicon_desktop ml-10 mt-5 mb-2"
                    />
                    <span className="optionboxtextDesk p-2 text-wrap">{option.name}</span>
                  </div>
                ))}
              </div>
            </div>  
          </div>
        </div>
        <div className="customviewbox border  mr-2 mt-2 ml-5 ">
          <div className="overflow-y-auto flex flex-col justify-between">
            <span className="text-right pr-5">
              <a href={handleNavigate(activeId) || '#'} target="_blank" rel="noopener noreferrer">
                <LaunchIcon className="cursor-pointer text-[#0778B6] hover:text-gray-700" />
              </a>
            </span>
                <IframeComp 
                key={iframeKey}
                className={"iframecomp w-full overflow-y-auto"} 
                endpoint={journeyRoutes.find(journey => journey.id === activeId)?.route}
                />            
            </div> 
        </div>
      </div>
    </div>
  );
};

export default PortfolioDesktop;
