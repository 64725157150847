import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Question, SectionWiseInsertion } from "../../api/SectionWiseInsertion";
import nextArrow from "../../assets/img/nextArrow.svg";
import backArrow from '../../assets/img/backArrow.svg';
import { FetechUserResponses } from "../../api/FetchUserResponses";
import Popup from "../common/popup/ConfirmationPopup";
import { SnackbarUi } from "../Snackbar/SnackbarUi";
import CreateNavbar from "../Navbar/Navbar";
import { getSectionData, isSectionDataPresent, removeSectionData, saveNumberToLocalStorage, updateSectionData } from "../common/StorageUtil";
import { areListsIdentical } from "../common/DeepEqual";
import { JourneyStatusCheck } from "../../api/JourneyStatusCheck";
import Title from "./TitleComp";
import { CheckBox } from "./CheckBoxComp";
import { RadioBox } from "./RadioBoxComp";
import Input from "./InputComp";
import { ResumeSelect } from "./ResumeSelectComp";

interface MainBodyProps {
  searchLogs: Question[];
  error: string | null;
  sectionHistory: number[];
  setSectionHistory: (history: number[]) => void;
  activeSectionIndex: number;
  setActiveSectionIndex: (history: number) => void;
  draftData: any;
  setDraftData: any;
  userAnswersHistory: any[];
  setUserAnswersHistory: any;
  setLoading: (loading: any) => void;
  isIframe: boolean;
}

const MainBody: React.FC<MainBodyProps> = ({ searchLogs, sectionHistory, setSectionHistory, activeSectionIndex,
  setActiveSectionIndex, draftData, setDraftData, userAnswersHistory, setUserAnswersHistory, setLoading, isIframe }) => {
  const { register, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState<string>(""); // State for error message
  const containerRef = useRef<HTMLDivElement>(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [backendMessage, setBackendMessage] = useState<string>("");
  const [isCreateJourneyDisabled, setIsCreateJourneyDisabled] = useState(true);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 768)
  const [bypassResumeCheck, setbypassResumeCheck] = useState(false)

   useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserAnswersHistory = async () => {
    try {
      const useremail = localStorage.getItem("Email");
      const userAnswers = await FetechUserResponses();
      setLoading(true);
      setUserAnswersHistory(userAnswers);
      updateSectionData(useremail, "DB_USER_Answers", userAnswers);
      setError(""); // Clear any previous errors
    } catch (error: unknown) {
      navigate('/');
      const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
      console.log(errorMessage); // Set error state for display
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [searchLogs, activeSectionIndex]);

  useEffect(() => { window.scrollTo(0, 0); }, [activeSectionIndex]);

  useEffect(() => { setIsCreateJourneyDisabled(isAllDataFilled()); });

  useLayoutEffect(() => { !isIframe && fetchUserAnswersHistory(); }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const initialDraftData: any = {};
        const useremail = localStorage.getItem("Email");
        activeSectionIndex = Number(getSectionData(useremail, 'activeSectionIndex'));
        if (activeSectionIndex === undefined || Number.isNaN(activeSectionIndex)) {
          activeSectionIndex = 0;
        }
        setActiveSectionIndex(activeSectionIndex);
        let setIntialDraft = false;
        userAnswersHistory.forEach(section => {
          if (section.sectionId === searchLogs[activeSectionIndex].sectionId) {
            section.questions.forEach((question: any) => {
              initialDraftData[question.id] = question.reply;
              setIntialDraft = true;
            });
          }
          if (section.sectionId === 1) {
            const fullNameQuestion = section.questions.find(
              (q: any) => q.question === 'Full Name'
            )
            if (fullNameQuestion && fullNameQuestion.reply) {
              localStorage.setItem('UserFullName', fullNameQuestion.reply)
            }
          }
        });
        if (setIntialDraft && !isSectionDataPresent(useremail, activeSectionIndex)) {
          setDraftData(initialDraftData);
        } else if (getSectionData(useremail, activeSectionIndex)) {
          setDraftData(getSectionData(useremail, activeSectionIndex));
        } else { setDraftData({}); }
      } catch (error) {
        navigate('/');
        const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
        setError(errorMessage);
        setIsSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [userAnswersHistory, activeSectionIndex, searchLogs]);
  const navigateBack = () => {
    const useremail = localStorage.getItem("Email");
    if (activeSectionIndex !== 0) {
      const updatedHistory = sectionHistory.slice(0, -1);
      updateSectionData(useremail, activeSectionIndex, draftData)
      setActiveSectionIndex(activeSectionIndex - 1);
      setSectionHistory(updatedHistory);
      updateSectionData(useremail, 'activeSectionIndex', activeSectionIndex - 1);
    } else { setShowConfirmation(true); }
  };

  const isStepValid = () => {
    const questionSection = searchLogs[activeSectionIndex];
    const requiredQuestionsArray = questionSection.fields.filter(question => question.required === true);
    return requiredQuestionsArray.every((requiredQuestion) => {
      const value = draftData[requiredQuestion.id];
      if (Array.isArray(value)) {
        return value.every(item => typeof item === 'string' && item.trim() !== '') && value.length !== 0;
      } else {
        return typeof value === 'string' && value.trim() !== '';
      }
    });
  };

  const navigateNext = async () => {
    const useremail = localStorage.getItem("Email");
    if (!isStepValid()) {
      alert('Please fill all required fields');
      return;
    }
    try {
      const response = await SectionWiseInsertion(activeSectionIndex, selectedFile, draftData, searchLogs, false, bypassResumeCheck);
      if (activeSectionIndex < searchLogs.length - 1) {
        const nextSectionId = searchLogs[activeSectionIndex + 1].sectionId;
        const prevSectionData = userAnswersHistory[searchLogs[activeSectionIndex].sectionId - 1]?.questions;
        if (prevSectionData !== undefined) {
          prevSectionData.forEach((element: any) => {
            element.reply = draftData[element.id];
          });
        } else { fetchUserAnswersHistory(); }
        removeSectionData(useremail, activeSectionIndex);
        updateSectionData(useremail, "DB_USER_Answers", userAnswersHistory);
        updateSectionData(useremail, 'activeSectionIndex', activeSectionIndex + 1);
        setActiveSectionIndex(activeSectionIndex + 1);
        setSectionHistory([...sectionHistory, nextSectionId]);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
      setError(errorMessage);
      setIsSnackbarOpen(true);
    }
  };

  const isAllDataFilled = () => {
    const useremail = localStorage.getItem("Email");
    const db_data = getSectionData(useremail, "DB_USER_Answers")
    let isDataChanged: boolean = true;
    for (let sectionIterator = 0; sectionIterator < searchLogs.length - 1; sectionIterator++) {
      const local_data = getSectionData(useremail, sectionIterator);
      if (local_data === undefined && db_data[sectionIterator] !== undefined) {
        isDataChanged = false;
      }
      else if ((local_data !== undefined) && (db_data[sectionIterator] !== undefined)) {
        const data = db_data[sectionIterator];
        const answers = data?.questions;
        for (let iterator = 0; iterator < answers.length; iterator++) {
          const id = answers[iterator].id;

          if (local_data[id] === answers[iterator].reply) {
            isDataChanged = false;
          } else if ((local_data[id] === "") && (db_data[sectionIterator] === "")) {
            isDataChanged = false;
          } else if (areListsIdentical(local_data[id], answers[iterator].reply)) {
            isDataChanged = false;
          } else { return true; }
        }
      }
      else if ((local_data !== undefined && db_data[sectionIterator] === undefined) && Object.keys(local_data).length > 0) {
        const keys = Object.keys(local_data);
        for (let keys_iterator = 0; keys_iterator < Object.keys(local_data).length; keys_iterator++) {
          if (local_data[keys[keys_iterator]] === "" || areListsIdentical(local_data[keys[keys_iterator]], [])) {
            return true;
          }
        }
        return true;
      }
      else { return true; }
    }
    return isDataChanged;
  };

 const onSubmit = async (e: React.FormEvent) => {
  if (!isCreateJourneyDisabled) {
    e.preventDefault();
    const useremail = localStorage.getItem('Email');
    if (!isStepValid()) {
      alert('Please fill all required fields');
      return;
    }
    setError("");
    try {
        const response = await SectionWiseInsertion(activeSectionIndex,selectedFile,draftData,searchLogs,false, bypassResumeCheck);
        const responseData = await response.json();
  
        if (response.ok) {
          const message = responseData.message || "Responses saved successfully!";
          if (message === "No changes detected. Please refer the existing career journey") {
            localStorage.setItem("QuestionnaireUpdated", "false");
          } else {
            localStorage.setItem("QuestionnaireUpdated", "true");
          }
          setIsSnackbarOpen(false);
          setBackendMessage(message);
  
          // Save values to localStorage
          localStorage.setItem("activeSectionIndex", JSON.stringify(activeSectionIndex));
          localStorage.setItem("selectedFile", JSON.stringify(selectedFile));
          localStorage.setItem("draftData", JSON.stringify(draftData));
          localStorage.setItem("searchLogs", JSON.stringify(searchLogs));
  
          navigate("/comparison", {state: {redirect: "Questionnaire-to-Comparison"}});
        } else if (!response.ok) {
          const message = responseData.error || "Something went wrong. Please try again!";
          const confirmationDialog = document.createElement('div')
          confirmationDialog.innerHTML = `
  <div style=" padding: 10px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); max-width: 500px; width: 90%; z-index: 1000; font-family: 'Inter', 'Helvetica', 'Arial', sans-serif; overflow: hidden;">
    <!-- Header -->
    <div class="dialog-header" style="position: relative; width: 95%; max-width: 470px; height: 80px; margin: 0 auto; margin-top: 15px; display: flex; border-radius: 18px; overflow: hidden; background: #FFE8C4;">
      <!-- Rectangle 6 -->
      <div class="rect-6" style="width: 18px; height: 80px; background: #E19D27; border-radius: 18px 0px 0px 18px;"></div>
      <!-- Rectangle 5 -->
      <div style="flex: 1; padding: 10px 15px; display: flex; align-items: center; gap: 10px;">
        <!-- SVG Icon -->
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="#E19D27" style="transform: translateX(-5px)";>
          <path d="M12 2L1 21h22L12 2zm1 16h-2v-2h2v2zm-2-4V8h2v6h-2z"/>
        </svg>
        <!-- Title and Description -->
        <div>
          <h2 style="font-family: 'Noto Sans'; font-size: 18px; font-weight: 700; line-height: 24px; margin: 0; color: #000000;">
            Name Mismatch Detected
          </h2>
          <p style="font-family: 'Noto Sans'; font-size: 14px; font-weight: 400; line-height: 18px; margin: 5px 0 0; color: #000000;">
            We've detected a mismatch in the name provided by you and the resume.
          </p>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div style="padding: 20px 20px 20px;"> <!-- Adjust padding for space below the header -->
      <div style="padding: 10px; margin-bottom: 20px; margin-left: 10px;">
        <p style="color: #333; font-size: 15px; word-wrap: break-word; w-full">
          <strong>${message}</strong> <br>
        </p>
      </div>
     <!-- Buttons -->
      <div style="display: flex; gap: 15px;">
        <button id="useResumeBtn" style="flex: 1; padding: 10px 10px; background: white; color: #333; border: 1px solid #333; border-radius: 6px; font-weight: 500; font-size: 14px; cursor: pointer; transition: background-color 0.2s;">
          Use this Resume
        </button>
        <button id="cancelBtn" style="flex: 1; padding: 10px 10px; background: white; color: #333; border: 1px solid #333; border-radius: 6px; font-weight: 500; font-size: 14px; cursor: pointer; transition: background-color 0.2s, color 0.2s;">
          Cancel and Update Resume
        </button>
      </div>
    </div>
  </div>
  <!-- Overlay -->
  <div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.2); backdrop-filter: blur(4px); z-index: 999;"></div>
`

          const style = document.createElement('style')
          style.innerHTML = `
  #useResumeBtn:hover, #cancelBtn:hover {
    background-color: rgb(2, 132, 199) !important;
    color: white !important;
    border: 1px solid rgb(2, 132, 199) !important;
  }
    @media (max-width: 768px) {
    .dialog-header, .rect-6 {
      height:100px !important;;
    }
}
`
          document.head.appendChild(style)
          document.body.appendChild(confirmationDialog)

          const useResumeBtn = confirmationDialog.querySelector('#useResumeBtn')
          const cancelBtn = confirmationDialog.querySelector('#cancelBtn')

          const removeDialog = () => {
            document.body.removeChild(confirmationDialog)
          }

          useResumeBtn?.addEventListener('click', () => {
            setbypassResumeCheck(true)
            removeDialog()
          })

          cancelBtn?.addEventListener('click', () => {
            setSelectedFile(null)
            removeDialog()
          })

        return
          setIsSnackbarOpen(false)
          setBackendMessage(message)
        } else {
          setIsSnackbarOpen(true);
          setBackendMessage('You already have a Journey under creation. Please wait for it to be completed before initiating another one. Please try after some time');
          setTimeout(() => { navigate("/myjourney", { state: { redirect: 'Questionnaire-to-MyJourney' } }) }, 3000)
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
        setIsSnackbarOpen(true);
        setBackendMessage(errorMessage);
        saveNumberToLocalStorage("backendMessage", errorMessage); // Save message to localStorage
        saveNumberToLocalStorage("isSnackbarOpen", "true"); // Save Snackbar state
      }
    }
  };  
  
  return (
    <div className="flex-grow overflow-y-auto px-5 md:px-10" ref={containerRef}>
      {showConfirmation && (
        <Popup
          onYes={() => navigate('/home')}
          onNo={() => setShowConfirmation(false)}
        />
      )}
      {searchLogs.map((section, index) => (
        <div key={section.sectionId} style={{ display: activeSectionIndex === index ? "block" : "none" }}>
          <div className="md:h-32">
             <div className="hidden sm:flex sm:justify-between items-center">
               {isWideScreen && (
                 <div
                   className="inline-flex text-back-prev-text font-medium uppercase bottom-10 right-10 items-center cursor-pointer font-sans text-sm sm:text-base w-28"
                   onClick={navigateBack} 
                 >
                <img src={backArrow} alt="back-arrow" className="pr-2 h-5" />
                {activeSectionIndex !== 0 ? "previous" : "back"}
                </div>
               )}
             {isWideScreen && <CreateNavbar page="questionnaire" />}
           </div>
          <div className="pb-4 border-b-2">
            <Title title={section.title} subTitle={section.subTitle} />
          </div>
         </div>
          <form>
            <div className="w-full sm:w-2/3">
              {section.fields.map((field) => (
                <div key={field.id} className="pt-8 text-sm sm:text-base">
                  {field.type === "checkbox" &&
                    <CheckBox
                      field={field}
                      draftData={draftData}
                      setDraftData={setDraftData}
                      isIframe={isIframe}
                    />}
                  {field.type === "radio" &&
                    <RadioBox
                      field={field}
                      draftData={draftData}
                      setDraftData={setDraftData}
                      isIframe={isIframe}
                    />}
                  {(field.type === "text" || field.type === "email") &&
                    <Input
                      register={register}
                      required={field.required}
                      field={field}
                      errors={errors}
                      value={draftData[field.id] || ''}
                      onChange={(value: string) => setDraftData((prev: any) => ({ ...prev, [field.id]: value }))}
                      disabled={isIframe}
                    />
                  }
                </div>
              ))}
              {activeSectionIndex === searchLogs.length - 1 && !isIframe &&
                <ResumeSelect
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  setbypassResumeCheck={setbypassResumeCheck}
                />}
            </div>
            {!isIframe && <div className="flex justify-end py-10">
              <button
                type="button"
                className={`flex justify-center font-medium text-white px-4 py-2 rounded-full bottom-10 right-10 inline-flex items-center text-sm sm:text-base ${activeSectionIndex < searchLogs.length - 1 ? 'bg-blue-1000 w-24' : `${isCreateJourneyDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-1000'} w-34`}`}
                onClick={activeSectionIndex < searchLogs.length - 1 ? navigateNext : onSubmit}
                disabled={activeSectionIndex >= searchLogs.length - 1 && isCreateJourneyDisabled}
              >
                {activeSectionIndex < searchLogs.length - 1
                  ? (<> Save <img src={nextArrow} alt="next-arrow" className="pl-2 font-medium" /></>)
                  : ('Create Journey')}
              </button>
            </div>}
            <div className="flex justify-center mt-10 md:mt-0 p-2  text-xs text-gray-500">
              All rights reserved by @skilljourney {new Date().getFullYear()}
            </div>
          </form>
        </div>
      ))}
      <SnackbarUi
        isSnackbarOpen={isSnackbarOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}
        backendMessage={backendMessage || error}
      />
    </div>
  );
};

export default MainBody;
