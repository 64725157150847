import React, { useEffect, useState, CSSProperties } from 'react'

const LoadingSpinner = () => {
  const [phase, setPhase] = useState(4) 
  const [prevPhase, setPrevPhase] = useState(4)

  useEffect(() => {
    const interval = setInterval(() => {setPrevPhase(phase); setPhase((prev) => (prev + 1) % 5) }, 1000)
    return () => clearInterval(interval)
  }, [phase])

  const getPathStyle = (currentPhase: number): CSSProperties => {
    const isActive = phase >= currentPhase
    const wasActive = prevPhase >= currentPhase
    const isFadingOut = !isActive && wasActive

    return {
      display: isActive || isFadingOut ? 'block' : 'none',
      opacity: isActive ? 1 : 0,
      transition: isFadingOut ? 'opacity 0.8s ease-out' : 'opacity 0.8s ease-in',
      strokeDasharray: '1000',
      strokeDashoffset: isActive ? '0' : '1000',
    }
  }

  const keyframes = `
    @keyframes draw {
      from { stroke-dashoffset: 1000;}
      to { stroke-dashoffset: 0;}}`

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-5 text-center box-border">
      <style>{keyframes}</style>
      <div className="relative w-[190px] h-[190px] sm:w-[160px] sm:h-[160px]">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[180px] h-[180px] sm:w-[150px] sm:h-[150px] rounded-full flex justify-center items-center">
          <svg
            viewBox="0 0 187 280"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[140px] h-[140px] sm:w-[120px] sm:h-[120px]"
          >
            {/* Left */}
            <g style={getPathStyle(0)}>
              <path d="M19.3152 112.079C19.3152 106.475 28.2158 106.475 28.2158 112.079C27.2268 115.046 30.853 149.769 26.2379 150.428L6.73359 161.691C1.78885 164.658 -2.82624 156.747 2.1185 154.109L19.6449 143.835L19.3152 112.079Z" fill="#0278B5"/>
              <path d="M7.40156 183.453C2.45682 186.42 -2.15827 178.838 2.78647 175.871L39.1578 154.719L38.4985 75.3831C38.4985 73.0756 40.4764 71.0977 42.7839 71.0977C45.0915 71.0977 47.0694 73.0756 47.399 75.3831L48.0583 157.026C51.0252 160.982 8.72016 181.805 7.40156 183.453Z" fill="#0278B5" />
            </g>
            {/* Middle */}
            <g style={getPathStyle(1)}>
              <path fillRule="evenodd" clipRule="evenodd" d="M88.043 179.494L86.7244 47.9091C86.7244 45.6015 84.7465 43.6236 82.1093 43.6236C79.8017 43.6236 77.8238 45.6015 77.8238 47.9091L79.1424 176.857L2.77361 221.47C0.46606 222.788 -0.193239 225.425 1.12536 227.403C2.44396 229.381 5.08115 230.04 7.05905 229.051L85.7354 183.45C87.054 182.461 88.043 181.142 88.043 179.494Z" fill="#FCB017"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M63.5939 63.4575C61.2864 63.4575 59.3085 65.4354 59.3085 67.743V165.924L2.44396 198.998C0.46606 200.317 -0.522888 202.954 0.79571 204.932C2.11431 206.91 4.7515 207.569 6.7294 206.58L65.9015 172.187C67.5497 171.198 68.209 169.55 68.209 167.902V67.4133C67.8794 65.4354 65.9015 63.4575 63.5939 63.4575Z" fill="#FCB017" />
              <path fillRule="evenodd" clipRule="evenodd" d="M107.547 176.857L106.888 33.0748C106.888 30.7673 104.91 28.7894 102.273 28.7894C99.9653 28.7894 97.9874 30.7673 97.9874 33.0748L98.6467 178.835C98.6467 180.483 99.306 182.131 100.954 182.791L180.29 227.074C182.268 228.392 185.235 227.403 186.224 225.425C187.542 223.447 186.553 220.81 184.575 219.492L107.547 176.857Z" fill="#FCB017" />
            </g>
            {/* Down */}
            <g style={getPathStyle(2)}>
              <path d="M76.8469 279.324C71.9021 282.291 67.287 274.709 72.2318 271.742L92.0657 260.149C95.3622 257.182 113.218 270.423 116.515 271.412C118.493 272.731 119.482 275.368 118.163 277.346C116.844 279.324 114.207 280.313 112.229 278.994L94.7029 269.05L76.8469 279.324Z" fill="#0278B5" />
              <path d="M56.9992 268.115C52.0545 271.082 47.4394 263.5 52.3841 260.533C54.6917 260.203 94.6892 233.447 96.0077 237.732L135.016 259.544C136.994 260.863 137.983 263.5 136.665 265.478C135.346 267.456 132.709 268.445 130.731 267.126L94.0299 246.633L56.9992 268.115Z" fill="#0278B5" />
              <path d="M37.1633 257.181C32.2185 260.148 27.6034 252.566 32.5482 249.599L91.7203 215.206C92.0499 210.261 154.189 248.61 155.837 248.61C160.782 251.247 156.496 259.214 151.552 256.192L94.3575 224.106L37.1633 257.181Z" fill="#0278B5" />
              <path d="M17.0148 245.921C12.0701 248.888 7.45501 241.306 12.3998 238.339L91.0761 192.737C92.3947 191.748 94.043 192.078 95.3616 192.737L174.697 237.02C176.675 238.339 177.664 240.976 176.345 242.954C175.027 244.932 172.39 245.921 170.412 244.602L93.7133 201.638L17.0148 245.921Z" fill="#0278B5" />
            </g>
            {/* Right */}
            <g style={getPathStyle(3)}>
              <path d="M184.257 152.41C189.202 155.047 184.917 163.014 179.972 159.992L160.138 148.729C155.523 150.377 158.16 37.6369 157.501 35.9887C157.501 30.3846 166.401 30.055 166.401 35.9887L167.061 142.411L184.257 152.355V152.41Z" fill="#0278B5" />
              <path d="M184.569 197.68C189.514 200.317 185.228 208.284 180.283 205.262C178.965 203.284 115.178 171.858 118.474 167.902L118.145 22.1419C118.145 19.8343 120.122 17.8564 122.43 17.8564C124.738 17.8564 126.715 19.8343 127.045 22.1419L127.375 165.594L184.569 197.68Z" fill="#0278B5" />
              <path fillRule="evenodd" clipRule="evenodd" d="M146.896 154.053L146.236 8.95244C146.236 6.64489 144.259 4.66699 141.621 4.66699C139.314 4.66699 137.336 6.64489 137.336 8.95244L137.995 156.361C137.995 158.009 138.655 159.657 140.303 160.317L179.971 182.458C181.949 183.777 184.915 182.788 185.904 180.81C186.893 178.832 186.234 176.195 184.256 174.876L146.896 154.053Z" fill="#0278B5" />
            </g>
            {/* Dot */}
            <g style={getPathStyle(4)}>
              <path fillRule="evenodd" clipRule="evenodd" d="M166.06 15.8781V4.28544C166.06 1.9779 164.082 0 161.445 0C159.137 0 157.159 1.9779 157.159 4.28544V15.8781C157.159 18.1857 159.137 20.1636 161.775 20.1636C164.082 20.1636 166.06 18.1857 166.06 15.8781Z" fill="#FCB017"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default LoadingSpinner;
