import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';
import NoSessions from './NoSessions';
import { fetchBookings } from '../../api/Counsellor';
import { isUserTokenValid } from '../common/ValidateToken';
import LoadingSpinner from '../common/LoadingSpinner';
import { UserInfo } from '../Counsellor/counsellorDashboard';

interface Session {
  id: string;
  clientName: string;
  user_info: UserInfo;
  counselor: {
    name: string;
    email: string;
    contact: number;
    profileUrl: string;
    bio: string;
    address: string;
    specialty?: string;
    rating: number;
  };
  date: string;
  time: string;
  timestamp: any;
  status:
    | 'Upcoming'
    | 'Completed'
    | 'Cancelled'
    | 'Updated'
    | 'Updated_draft'
    | 'Awaiting_feedback'
    | 'Expired'
    | 'Review_in_process';
  meetingUrl: string;
  feedback?: string;
}

const BookedSessionsPage: React.FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<
    'All' | 'Upcoming' | 'Completed' | 'Cancelled' | 'Awaiting_feedback' | 'Expired' | 'Review_in_process'
  >('All');
  const [bookedSessions, setBookedSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const statusOrder: Record<string, number> = {
    Upcoming: 1,
    Awaiting_feedback: 2,
    Review_in_process: 3,
    Updated_draft: 3,
    Completed: 4,
    Expired: 5,
    Cancelled: 6,
    Updated: 2,
  };

  // Utility to normalize status
  const normalizeStatus = (status: string): string => {
    switch (status) {
      case 'Updated':
      case 'Awaiting_Feedback':
        return 'Awaiting_feedback';
      case 'Updated_draft':
        return 'Review_in_process';
      default:
        return status;
    }
  };

  // Utility to get display names for statuses
  const getStatusDisplayName = (status: string): string => {
    switch (status) {
      case 'Review_in_process':
        return 'Review in process';
      case 'Awaiting_feedback':
        return 'Awaiting feedback';
      default:
        return status;
    }
  };

  // Sort sessions based on normalized statuses and date-time
  const sortSessions = (sessions: Session[]) => {
    return [...sessions].sort((a, b) => {
      const statusA = statusOrder[normalizeStatus(a.status)] || 999;
      const statusB = statusOrder[normalizeStatus(b.status)] || 999;

      if (statusA !== statusB) {
        return statusA - statusB;
      }

      // Parse date in DD/MM/YYYY format
      const parseDate = (dateStr: string) => {
        const [day, month, year] = dateStr.split('/').map(Number)
        return new Date(year, month - 1, day)
      }

      // Parse time in 12-hour format
      const parseTime = (timeStr: string) => {
        const [time, period] = timeStr.split(' ')
        let [hours, minutes] = time.split(':').map(Number)

        if (period === 'PM' && hours !== 12) {
          hours += 12
        }
        if (period === 'AM' && hours === 12) {
          hours = 0
        }

        return { hours, minutes }
      }

      const dateA = parseDate(a.date)
      const dateB = parseDate(b.date)

      // Compare dates first
      if (dateA.getTime() !== dateB.getTime()) {
        return dateA.getTime() - dateB.getTime()
      }

      // If dates are the same, compare times
      const timeA = parseTime(a.time)
      const timeB = parseTime(b.time)

      // Compare hours first
      if (timeA.hours !== timeB.hours) {
        return timeA.hours - timeB.hours
      }

      // If hours are the same, compare minutes
      return timeA.minutes - timeB.minutes
    })
  }

  const handleSessionClick = (session: Session) => {
    navigate(`/sessions/${session.id}`, { state: { session } });
  };

  const handleBookClick = () => {
    navigate('/counselling');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const isValid = await isUserTokenValid();
        if (!isValid) {
          navigate('/login');
          return;
        }

        const response = await fetchBookings();
        const sessions = response.bookings.map((booking: any) => ({
          id: booking.meeting_id,
          clientName: 'N/A',
          user_info: booking.user_info || {
            email: 'placeholder@example.com',
            name: 'Placeholder Name',
            profileIcon: '',
          },
          counselor: {
            name: booking.counsellor_info.name || 'Unknown',
            email: booking.counsellor_info.email || '',
            contact: booking.counsellor_info.contact || 0,
            profileUrl: booking.counsellor_info.profile_url || 'https://via.placeholder.com/150',
            bio: booking.counsellor_info.bio || '',
            address: booking.counsellor_info.address || '',
            specialty: booking.counsellor_info.specialty || '',
            rating: booking.counsellor_info.rating || 0.0,
          },
          date: booking.date,
          time: booking.time,
          status: normalizeStatus(booking.status),
          meetingUrl: booking.meeting_url,
          timestamp: booking.timestamp,
          feedback: booking.feedback,
        }));

        setBookedSessions(sortSessions(sessions));
      } catch (error: any) {
        console.error('Error fetching booked sessions:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const getUniqueStatuses = (sessions: Session[]): Session['status'][] => {
    return sessions
      .reduce((acc: Session['status'][], session) => {
        const normalized = normalizeStatus(session.status);
        if (!acc.includes(normalized as Session['status'])) {
          acc.push(normalized as Session['status']);
        }
        return acc;
      }, [])
      .sort((a, b) => (statusOrder[a] || 999) - (statusOrder[b] || 999));
  };

  const filters = ['All', ...getUniqueStatuses(bookedSessions)];

  const filteredSessions = bookedSessions.filter(
    (session) => filter === 'All' || normalizeStatus(session.status) === filter
  );

  return (
    <>
      <CreateNavbar page="home" />
      <div className="bg-gray-100 min-h-screen p-4 md:p-6 lg:p-8 font-sans">
        {bookedSessions.length === 0 ? (
          <NoSessions handleBookClick={handleBookClick} />
        ) : (
          <>
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-center mb-6 md:mb-8 lg:mb-10 text-gray-900">
              Your Booked Sessions
            </h1>

            <div className="mb-4 md:mb-6 flex justify-end">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value as typeof filter)}
                className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange text-sm md:text-base"
              >
                {filters.map((status) => (
                  <option key={status} value={status}>
                    {status === 'All' ? 'All Sessions' : getStatusDisplayName(status)}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {filteredSessions.map((session) => (
                <div
                  key={session.id}
                  className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-md border-t-4 border-custom-orange transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col"
                >
                  <div className="flex items-center mb-4 sm:mb-6">
                    <img
                      src={session.counselor.profileUrl}
                      alt={session.counselor.name}
                      className="w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 rounded-full border-4 border-blue-500 mr-4"
                    />
                    <div>
                      <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800 mb-1 sm:mb-2">
                        {session.counselor.name}
                      </h3>
                      <p className="text-gray-600 text-sm sm:text-base mb-1">
                        <strong>Date:</strong> {session.date}
                      </p>
                      <p className="text-gray-600 text-sm sm:text-base mb-1">
                        <strong>Time:</strong> {session.time}
                      </p>
                      <p
                        className={`text-gray-600 text-sm sm:text-base font-semibold ${
                          session.status === 'Completed' || session.status === 'Updated_draft'
                            ? 'text-green-500'
                            : session.status === 'Cancelled'
                            ? 'text-red-500'
                            : session.status === 'Awaiting_feedback'
                            ? 'text-orange-500'
                            : session.status === 'Expired'
                            ? 'text-gray-500'
                            : 'text-yellow-500'
                        }`}
                      >
                        <strong>Status:</strong> {getStatusDisplayName(session.status)}
                      </p>
                    </div>
                  </div>
                  <div className="flex-grow flex items-end">
                    <button
                      onClick={() => handleSessionClick(session)}
                      className="w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookedSessionsPage;
