import React, { useEffect, useRef, useState } from "react";
import './CollegePortfolioMobile.css';
import questionnaire from '../../../assets/img/User/Portfolio/questionnaire.svg';
import stdjourney from '../../../assets/img/User/Portfolio/stdjourney.svg';
import pcj from '../../../assets/img/User/Portfolio/pcjourney.svg';
import counselling from '../../../assets/img/User/Portfolio/counselling.svg';
import trainings from '../../../assets/img/User/Portfolio/trainings.svg';
import certificates from '../../../assets/img/User/Portfolio/certificate.svg';
import jobs from '../../../assets/img/User/Portfolio/jobs.svg';
import { useLocation, useNavigate } from "react-router-dom";
import IframeComp from "../../user/portfolio/UserIframeComp";
import ReactQuill from "react-quill";
import Carousel from "react-multi-carousel";
import { Student } from "../CollegeDashboardMobile";
import { isUserTokenValid } from "../../common/ValidateToken";
import { fetchUserDetails } from "../../../api/UserMetadata";
import { FetchPremiumJourneybyVerion } from "../../../api/FetchHtmlJourney";
import SaveIcon from '@mui/icons-material/Save';
import { addStudentNote, fetchStudentNotes, updateStudentNote } from "../../../api/College";

interface JourneyRoute {
    id: number;
    route: string;
}

interface Note {
notes: string;
timeStamp?: string;
}

interface NoteResponse {
student_email?: string;
notes: Note[];
}

const journeyRoutes: JourneyRoute[] = [
    { id: 1, route: "/questionnaire" },
    { id: 2, route: "/myjourney" },
    { id: 3, route: "/premiumjourney" },
    //{ id: 4, route: "/booked-sessions" },
    { id: 5, route: "/comingsoon" },
    { id: 6, route: "/comingsoon" },
    { id: 7, route: "/jobs" },
    { id: 8, route: "/comingsoon" }
];


interface Option {
    id: number;
    name: string;
    logo: string;
}
interface ViewOption{
    selectid:number;
    setId:any;
}
const CollegePortfolioMbView: React.FC<ViewOption> = ({selectid,setId}) => {
    const navigate = useNavigate();
    const [activeId,setActiveID]=useState<number>(selectid || 1);
    const carouselRef = useRef<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showNotes, setShowNotes] = useState<boolean>(false);
    const location = useLocation();
    const [northStarTitle, setNorthStarTitle] = useState<string>("Loading...");
    const [noteTimestamp, setNoteTimestamp] = useState<string>("");
    const session = location.state as Student;
    const [noteContent, setNoteContent] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [isExistingNote, setIsExistingNote] = useState<boolean>(false);

    const options: Option[] = [
        { id: 1, name: "Journey Builder", logo: questionnaire },
        { id: 2, name: "Standard Journey", logo: stdjourney },
        { id: 3, name: "Premium Journey", logo: pcj },
        //{ id: 4, name: "Counselling", logo: counselling },
        { id: 5, name: "Trainings", logo: trainings },
        { id: 6, name: "Certificates", logo: certificates },
        { id: 7, name: "Jobs", logo: jobs },
        { id: 8, name: "Resume Builder", logo: jobs },
    ];

    const fetchPremiumJourney = async () => {
        try {
        const isValid = await isUserTokenValid();
        if (!isValid) {
            navigate("/login", { state: { page: "portfolio" } });
            return;
        }
    
        if (!session.id) {
            console.error("Session ID is missing");
            setNorthStarTitle("Error: Session ID is required");
            return;
        }
    
        localStorage.setItem("Email", session.id);
    
        try {
            const userDetails = await fetchUserDetails(session.id, navigate);
            const userLevel = userDetails?.userLevel;
            if (userLevel) {
            localStorage.setItem("user_status", userLevel);
            }
            const publishedJourney = userDetails?.PublishedJourney;
            const version = publishedJourney?.version;
    
            if (!version) {
            setNorthStarTitle("Your Premium Journey version is missing");
            return;
            }
    
            const response = await FetchPremiumJourneybyVerion(version);
            const northStarData = response?.career_premium_journey?.NorthStar;
    
            if (northStarData?.Title) {
            setNorthStarTitle(northStarData.Title);
            } else {
            setNorthStarTitle("Your Premium Journey is not yet created");
            }
        } catch (innerError) {
            setNorthStarTitle("Error loading Premium Journey details");
        } 
        } catch (error) {
        setNorthStarTitle("Error loading title");
        }
    };
    
    useEffect(() => {
        fetchPremiumJourney();
    }, []);

    const fetchNotes = async () => {
        if (!session.id) return;
    
        try {
        setLoading(true);
        const response: NoteResponse = await fetchStudentNotes(session.id);
    
        if (response?.notes?.length) {
            const mostRecentNote = response.notes.sort((a, b) => (b.timeStamp ? parseInt(b.timeStamp) : 0) - (a.timeStamp ? parseInt(a.timeStamp) : 0))[0]; 
    
            setNoteContent(mostRecentNote.notes);
            setNoteTimestamp(mostRecentNote.timeStamp || ""); 
            setIsExistingNote(true);
        } else {
            setNoteContent("");
            setNoteTimestamp("");
            setIsExistingNote(false);
        }
        } catch (error) {
        console.error("Error fetching notes:", error);
        setNoteContent("");
        setNoteTimestamp("");
        setIsExistingNote(false);
        } finally {
        setLoading(false);
        }
    };
    useEffect(() => {
    fetchNotes();
    }, [session.id]);


    const handleNoteChange = (content: string) => {
    setNoteContent(content);
    };
    
    const handleSaveNote = async () => {
        if (!session.id || noteContent.trim() === "") return;
    
        try {
        const studentEmail = session.id || "";
        const noteDetails: { student_email: string; notes: { notes: string; timeStamp?: string }[] } = {
            student_email: studentEmail,
            notes: [{ notes: noteContent, ...(isExistingNote && { timeStamp: noteTimestamp }) }],
        };
    
        const response = isExistingNote
            ? await updateStudentNote(noteDetails)
            : await addStudentNote(noteDetails);
    
        if (response.success) {
            if (!isExistingNote) {
            setIsExistingNote(true);
            await fetchNotes(); 
            }
        }
        } catch (error) {
        console.error("Error saving note:", error);
        }
    };  

    const setData = (id: number) => {
        setActiveID(id);
        setShowNotes(false); 
        scrollToActive(id);
    };

    const responsive = {
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5,
            slidesToSlide: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 3
        }
    };

    const CustomDot = ({ onClick, active, index }: any) => {
        return (
            <button
                className={`custom-dot ${active ? 'active' : ''}`}
                onClick={() => { onClick(); setCurrentSlide(index); }}
            />
        )};

    const scrollToActive = (id: number) => {
        const index = options.findIndex((option) => option.id === id);
        if (carouselRef.current) {
            const currentBreakpoint = window.innerWidth <= 464 ? 'mobile' : 'tablet';
            const visibleItems = responsive[currentBreakpoint].items;
            const totalOptions = options.length;
            const adjustedIndex =
                index >= totalOptions - visibleItems ? totalOptions - visibleItems : Math.max(0, index - Math.floor(visibleItems / 2));
            carouselRef.current.goToSlide(adjustedIndex);
            setCurrentSlide(Math.floor(adjustedIndex / visibleItems));
        }
    };

    useEffect(() => {
        scrollToActive(activeId);
    }, [activeId]);

    return (
        <div className="overflow-y-auto flex flex-col w-full h-screen">
            <div className="flex sticky top-4 justify-center px-4 mb-5 bg-white z-10">
                <div className="w-full pt-3 max-h-[200px]">
                    <Carousel
                        ref={carouselRef}
                        responsive={responsive}
                        infinite={false}
                        autoPlay={false}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        itemClass="px-2"
                        containerClass="hide-scrollbar"
                        ssr={true}
                        showDots={true}
                        customDot={<CustomDot />}
                        dotListClass="custom-dot-list-style"
                        afterChange={(previousSlide, { currentSlide }) => {
                            setCurrentSlide(Math.floor(currentSlide / (window.innerWidth <= 464 ? 3 : 5)));
                        }}
                    >
                        {options.map((option) => (
                        <div
                            key={option.id}
                            className={`optionbox_college_mobile justify-start flex flex-col ${activeId === option.id ? 'bg-[#ECFAFD]' : ''}`}
                            onClick={() => setData(option.id)} 
                        >
                            <img
                                src={option.logo}
                                alt={option.name}
                                className="w-8 h-8 mt-4"
                            />
                            <span className=" optionboxtext_college_mobile text-wrap mt-2">{option.name}</span>
                        </div>
                    ))}
                    </Carousel>
                </div>
            </div>
            <div className="flex-1 overflow-y-auto">
                <div className="flex justify-between items-center px-4 pt-4 border-b border-gray-300">
                    <div className="flex space-x-4 border-b border-gray-300">
                        <span
                            className={`cursor-pointer px-4 py-2 ${
                                showNotes
                                    ? 'text-[#0778B6] border-b-2 border-[#0778B6] font-bold'
                                    : 'text-gray-500 hover:text-[#0778B6]'
                            }`}
                            onClick={() => setShowNotes(true)}
                        >
                            Notes
                        </span>
                        <span
                            className={`cursor-pointer px-4 py-2 ${
                                !showNotes
                                    ? 'text-[#0778B6] border-b-2 border-[#0778B6] font-bold'
                                    : 'text-gray-500 hover:text-[#0778B6]'
                            }`}
                            onClick={() => setShowNotes(false)}
                        >
                            {options.find((option) => option.id === activeId)?.name}
                        </span>
                    </div>
                    <div
                      className="flex justify-end mr-2 cursor-pointer"
                      onClick={handleSaveNote}
                    >
                      <SaveIcon className="text-[#0778B6]" />
                    </div>
                </div>
    
                <div>
                    {!showNotes ? (
                        <IframeComp
                            className="w-full min-h-[400px]"
                            endpoint={journeyRoutes.find((journey) => journey.id === activeId)?.route}
                        />
                    ) : (
                        <div className="p-4 h-[400px] flex flex-col">
                            <ReactQuill
                                value={noteContent}
                                onChange={handleNoteChange}
                                className="flex-grow w-full"
                                placeholder="Type your notes..."
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CollegePortfolioMbView;
