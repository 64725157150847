import { BackendApi } from "./apiConfig/ApiFormatter";



export const FetechUserProfile = async (): Promise<any[]> => {
    const apiUrl = BackendApi() + "getUserProfile";
    const isTestUser=localStorage.getItem("Name")
    if(isTestUser==="TestUser"){
        return ["https://media.istockphoto.com/id/1298261537/vector/blank-man-profile-head-icon-placeholder.jpg?s=612x612&w=0&k=20&c=CeT1RVWZzQDay4t54ookMaFsdi7ZHVFg2Y5v7hxigCA="];
    }

    try {
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `${idToken}` 
            }
        });

        if (!response.ok) {
            console.log('Error fetching user questionnaire');
        }

        const profileData = await response.json();
        
        return profileData;
    } catch (error) {
        console.error('Error fetching user questionnaire:', error);
        throw error; // Rethrow error to handle it in the component
    }
};