import React, { useState } from "react";
import './CollegePortfolioMobile.css';
import questionnaire from '../../../assets/img/User/Portfolio/questionnaire.svg';
import stdjourney from '../../../assets/img/User/Portfolio/stdjourney.svg';
import pcj from '../../../assets/img/User/Portfolio/pcjourney.svg';
import counselling from '../../../assets/img/User/Portfolio/counselling.svg';
import trainings from '../../../assets/img/User/Portfolio/trainings.svg';
import certificates from '../../../assets/img/User/Portfolio/certificate.svg';
import jobs from '../../../assets/img/User/Portfolio/jobs.svg';
import { useLocation, useNavigate } from "react-router-dom";
import resume from '../../../assets/img/User/Portfolio/resume.svg';
import CollegePortfolioMbView from "./CollegePortfolioMbView";
import CreateNavbar from "../../Navbar/Navbar";
import { Student } from "../CollegeDashboardMobile";

const CollegePortfolioMobile: React.FC = () => {
  const [id,setId]=useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const session = location.state as Student;

  return (
    <div className="overflow-y-auto flex flex-col h-screen">
      <CreateNavbar page="college-dashboard" />
      <div className="box_college_mobile flex flex-col">
      <div className="flex w-full max-h-[60px] items-center">
        <div className="flex items-center mt-2">
          <img src={session.ProfileUrl} alt="Profile" className="profile_college_mobile ml-[25px] rounded-full h-[50px] w-[50px] object-cover"/>
          <span className="name_college_mobile  max-w-[140px]">{session.name}</span>
        </div>
        <div className="flex justify-end ml-auto">
        {localStorage.getItem('user_status') === 'Standard' ? (
          <img src={stdjourney} className="w-[30px] h-[40px] object-contain" alt="" /> 
        ) : 
          <img src={pcj} className="w-[30px] h-[40px] object-contain" alt="" />
        }
        </div>
      </div>
        <label className="flex line_college_mobile mt-[65px]">
        </label>
        <div className="flex flex-col northstartbox_college_mobile mt-[30px] ml-[13px] ">
            <p className="text-gray-600 text-sm sm:text-base">
              <strong>Education:</strong> <span className="font-normal">{session.education}</span>
            </p>
            <div className="flex flex-row  ">
              <p className="text-gray-600 text-sm sm:text-base mr-[13px]">
                <strong>Year: </strong> <span className="font-normal">{session.year}</span>
              </p>
              <p className="text-gray-600 text-sm sm:text-base mr-[13px]">
                <strong>CGPA: </strong> <span className="font-normal">{session.cgpa}</span>
              </p>
            </div>
          <p className="text-gray-600 text-sm sm:text-base mb-1">
          <strong>Highlights: </strong><span className="font-normal">{session.highlights}</span>
          </p>
        </div>
      </div>
      {
        id===0 ?(
          <div className="flex flex-col pt-8 pl-[74px] pr-[74px]">
            <div className="flex flex-col text-align-center">
                <div className="flex justify-between ">
                    <div className="optionbox_college_mobile text-wrap" onClick={()=>setId(1)}>
                      <img src={questionnaire}  className="boxicon_college_mobile mb-[4px]  mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext_college_mobile  text-center text-wrap">Journey Builder</span>
                    </div>
                    <div className="optionbox_college_mobile text-wrap"  onClick={()=>setId(2)}>
                      <img src={stdjourney}  className="boxicon_college_mobile mb-[4px]  mt-[14px]" alt="Standard Journey" />
                      <span className="optionboxtext_college_mobile text-center text-wrap">Standard Journey</span>
                    </div> 
                </div>
                <div className="mt-[15px] justify-between flex">
                    <div className="optionbox_college_mobile text-wrap" onClick={()=>setId(3)}>
                      <img src={pcj}  className="boxicon_college_mobile mb-[4px] mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext_college_mobile text-center  text-wrap">Premium Journey</span>
                    </div>
                    {/* <div className="optionbox_college_mobile text-wrap" onClick={()=>setId(4)}>
                      <img src={counselling}  className="boxicon_college_mobile mb-[4px] mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext_college_mobile text-center  text-wrap">Counselling</span>
                    </div>  */}
                    <div className="optionbox_college_mobile text-wrap" onClick={()=>setId(5)}>
                      <img src={trainings}  className="boxicon_college_mobile mb-[4px] mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext_college_mobile text-center text-wrap">Trainings</span>
                    </div>
                </div>
                <div className="mt-[15px] justify-between flex">
                    <div className="optionbox_college_mobile text-wrap" onClick={()=>setId(6)}>
                      <img src={certificates}  className="boxicon_college_mobile mb-[4px] mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext_college_mobile text-center text-wrap">Certificates</span>
                    </div>      
                    <div className="optionbox_college_mobile items-center text-wrap" onClick={()=>setId(7)}>
                      <img src={jobs}  className="boxicon_college_mobile mb-[4px] mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext_college_mobile  text-center text-wrap">Jobs</span>
                    </div>       
                </div>
                <div className="mt-[15px] justify-between flex">
                    
                    <div className="optionbox_college_mobile text-wrap" onClick={()=>setId(8)}>
                      <img src={resume}  className="boxicon_college_mobile mb-[4px] mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext_college_mobile text-center text-wrap">Resume</span>
                    </div>  
                </div>
            </div>
          </div>
        ):(
          <CollegePortfolioMbView selectid={id} setId={setId}/>
        )
      }
      
    </div>
  );
};

export default CollegePortfolioMobile;
