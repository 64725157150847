import { useState } from "react";
import northStar from '../../assets/img/premiumJourney/NorthStar.svg';
import triangle from '../../assets/img/premiumJourney/triangle.svg';
import triangleInverse from '../../assets/img/premiumJourney/triangleInverse.svg';

interface RightPremiumSidebarProps {
    premiumJourney: any;
}

// Utility function to check if a value is valid
const isValid = (value: any) => {
    if (value === null || value === undefined || value === "") return false;
    return true;
};

const RightPremiumSidebar: React.FC<RightPremiumSidebarProps> = ({ premiumJourney }) => {
    const [isHovered, setIsHovered] = useState(true);
    
    return (
        <div className="w-1/5">
            <div className="rounded-b-none rounded-t-lg md:rounded-t-lg md:rounded-b-lg px-4 py-4 bg-[#141736] text-white font-sans">
                <div className="flex flex-row justify-start items-center font-bold cursor-grab text-xl" onClick={() => setIsHovered(!isHovered)}>
                    <img src={northStar} alt="northstar" className="size-10" />
                    NorthStar
                    <img src={isHovered ? triangleInverse : triangle} alt="triangle" className="size-3 ml-4 mt-1" />
                </div>
                <div className="text-base font-semibold">
                    {isValid(premiumJourney.NorthStar?.Title) ? premiumJourney.NorthStar.Title : "No Title Available"}
                </div>
                {isHovered && (
                    <div className="flex flex-col mt-3 gap-3 h-[24rem] w1400:h-[330px] flex-grow overflow-y-auto pb-10">
                        {isValid(premiumJourney.NorthStar?.VisionStatement) && (
                            <p>
                                <span className="pr-1 font-semibold text-base">Vision:</span> <br />
                                <span className="">{premiumJourney.NorthStar.VisionStatement.replace(/\n/g, "")}</span>
                            </p>
                        )}
                        {isValid(premiumJourney.NorthStar?.LongTermGoals) && (
                            <p>
                                <span className="font-semibold text-base">Long Term Goals:</span><br />
                                {Array.isArray(premiumJourney.NorthStar.LongTermGoals) ? (
                                    <ul className="list-disc text-sm px-4 py-2">
                                        {premiumJourney.NorthStar.LongTermGoals.map((goal: string, index: number) => (
                                            <li key={index}>{goal}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-sm">{premiumJourney.NorthStar.LongTermGoals.replace(/\n/g, "")}</p>
                                )}
                            </p>
                        )}
                        {isValid(premiumJourney.NorthStar?.MotivationalStatement) && (
                            <p className="pr-1 font-medium italic text-sm">
                                {`"${premiumJourney.NorthStar.MotivationalStatement.replace(/\n/g, "")}"`}
                            </p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default RightPremiumSidebar;
