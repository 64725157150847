import component from '../../../assets/img/premiumJourney/component.png';
import frame2 from '../../../assets/img/premiumJourney/tick.svg';
import frame3 from '../../../assets/img/premiumJourney/image 8.svg';
import ReactMarkdown from 'react-markdown';

interface YearWithoutQuotersComp {
    year: any;
    premiumJourney: any;
    i: number;
    isValid: (history: string) => boolean;
}

const YearWithoutQuoters: React.FC<YearWithoutQuotersComp> = ({ year, premiumJourney, i, isValid }) => {
    if (i >= 5) return null;
    return (
        <div key={i} id={`year${i + 1}`}>
            <div className="year1-quarter1 my-5">
                <div className="bg-[#0778B6] rounded-lg px-4 py-4">
                    <div className="flex">
                        <img className="w-6 h-6 desk-icon" src={component} alt="icon" />
                        <img className="w-7 h-7 mob-icon" src={component} alt="icon" />
                        <p className="text-xl text-white font-semibold pl-3">
                            {`Year ${i + 1}`}
                        </p>
                    </div>
                </div>
                <div className="content">
                    {(i === premiumJourney.Milestones.length - 1 ? year.Y6to10_SMARTGoals : year[`Y${i + 1}_SMARTGoals`])?.length > 0 &&
                        Array.isArray((i === premiumJourney.Milestones.length - 1 ? year.Y6to10_SMARTGoals : year[`Y${i + 1}_SMARTGoals`])) && (
                            <div>
                                <p className="text-base mb-1 font-semibold pt-3 pl-3 lg:pl-4 sm:pl-2 text-[#0778b6]">Goals</p>
                                {(i === premiumJourney.Milestones.length - 1
                                    ? year.Y6to10_SMARTGoals
                                    : year[`Y${i + 1}_SMARTGoals`])
                                    .filter((goal: string) => goal && goal.trim() !== "") // Filter out null, undefined, or empty goals
                                    .map((goal: string, index: number) => (
                                        <div key={index} className="flex flex-row px-4 md:px-6 gap-2">
                                            <div className="font-semibold">{index + 1}.</div>
                                            <ReactMarkdown>
                                                {goal}
                                            </ReactMarkdown>
                                        </div>
                                    ))}
                            </div>
                        )}
                    <div>
                        {(i === premiumJourney.Milestones.length - 1
                            ? year.Y6to10_ActionPlan
                            : year[`Y${i + 1}_ActionPlan`])?.length > 0 &&
                            Array.isArray(i === premiumJourney.Milestones.length - 1
                                ? year.Y6to10_ActionPlan
                                : year[`Y${i + 1}_ActionPlan`]) &&
                            <div>
                                <p className="text-base mb-1 font-semibold pt-3 pl-3 lg:pl-4 sm:pl-2 text-[#0778b6]">Plan</p>
                                <ul className="list-disc pl-8 md:pl-10 sm:pr-2 flex flex-col">
                                    {(i === premiumJourney.Milestones.length - 1
                                        ? year.Y6to10_ActionPlan
                                        : year[`Y${i + 1}_ActionPlan`])
                                        .filter((actionplan: any) => actionplan ) // Filter out null, undefined, or empty action plans
                                        .map((actionplan: any, id: number) => (
                                            <li key={id} className="text-base font-normal leading-6">
                                                {/* If actionplan is an object with description and url */}
                                                <ReactMarkdown>
                                                    {typeof actionplan === 'object' && actionplan.description ? (
                                                        actionplan.description
                                                        
                                                    ) : (
                                                        actionplan.trim("")
                                                    )}
                                                </ReactMarkdown>
                                            </li>
                                        ))}
                                </ul>
                            </div>}

                        {(i === premiumJourney.Milestones.length - 1 ? year.Y6to10_ExpectedOutcomes : year[`Y${i + 1}_ExpectedOutcomes`])?.length > 0 &&
                            Array.isArray(i === premiumJourney.Milestones.length - 1 ? year.Y6to10_ExpectedOutcomes : year[`Y${i + 1}_ExpectedOutcomes`]) && (
                                <div className="bg-custom rounded-lg mt-6 p-3 md:p-4">
                                    <p className="text-base font-semibold pb-1">Expected Outcomes:</p>
                                    <ul className="list-disc pl-5">
                                        {(i === premiumJourney.Milestones.length - 1
                                            ? year.Y6to10_ExpectedOutcomes
                                            : year[`Y${i + 1}_ExpectedOutcomes`])
                                            .filter((outcome: string) => outcome && outcome.trim() !== "") // Filter out null, undefined, or empty outcomes
                                            .map((outcome: string, idx: number) => (
                                                <li key={idx} className="text-base font-normal leading-6">
                                                    <ReactMarkdown>
                                                        {outcome}
                                                    </ReactMarkdown>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            )}
                    </div>
                    {isValid((i === premiumJourney.Milestones.length - 1)
                        ? year.Y6to10_AlignmentWithNorthStar
                        : year[`Y${i + 1}_AlignmentWithNorthStar`]) &&
                        <div className="bg-[#BCE7F3] flex flex-col-2 my-5 rounded-lg">
                            <div className=" flex gap-2 flex-col tab-custom md:w-4/5 py-5 pl-4 md:pl-4">
                                <div className="flex flex-row gap-2 ">
                                    <img className="size-5" src={frame2} alt="user" />
                                    <p className="text-semibold text-sm year-color">
                                        {`Year ${i + 1}`}
                                    </p>
                                </div>
                                <p className=" text-xl font-semibold">Keep it up</p>
                                <p className="text-sm md:text-base font-normal">
                                <ReactMarkdown>{i === premiumJourney.Milestones.length - 1 ? year.Y6to10_AlignmentWithNorthStar : year[`Y${i + 1}_AlignmentWithNorthStar`]}</ReactMarkdown>
                                </p>
                            </div>
                            <div className='flex justify-end items-start md:w-1/5 '>
                                <img src={frame3} className='' alt='troffy' />
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default YearWithoutQuoters;
