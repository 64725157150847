import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

interface HrDetailsProps {
  name: string;
  category: string;
  company_name: string;
  company_address: string;
  company_url: string;
  company_contact_details: string;
  contact_number: string;
  email: string;
  photo_url: string;
  remarks: string;
  onExpandedChange?: (expanded: boolean) => void 
}

const formatUrl = (url: string) => {
  try {
    new URL(url);
    return url;
  } catch {
    return `https://${url}`;
  }
};

const HrDetailsMobile: React.FC<HrDetailsProps> = ({name,category,company_name,company_address,company_url,contact_number,email,photo_url,remarks,onExpandedChange}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);
    onExpandedChange?.(newExpandedState);
  };

  return (
    <div
      className="p-4 bg-white rounded-lg shadow-md flex flex-col max-w-full mx-auto"
      style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)' }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={photo_url || 'https://via.placeholder.com/150'}
            alt="HR Profile"
            className="w-16 h-16 rounded-full mr-4 object-cover"
          />
          <h6 className="text-lg font-normal">{name}</h6>
        </div>
        <button onClick={toggleExpanded} className="focus:outline-none flex items-center">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </button>
      </div>

       {expanded && (
        <div className="mt-4">
          <p className="text-sm font-bold text-gray-600">{company_name}</p>
          <p className="text-sm text-gray-600">{category}</p>
          <p className="text-sm text-gray-600">{email}</p>
          <p className="text-sm text-gray-600">{contact_number}</p>
          <p className="text-sm text-gray-600">{company_address}</p>
          <a
            href={formatUrl(company_url)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-500 underline"
          >
            Visit Company Website
          </a>
          <p className="text-sm text-gray-600 mt-2">{remarks}</p>
        </div>
      )}
    </div>
  );
};

const HrDetailsDesktop: React.FC<HrDetailsProps> = ({name,category,company_name,company_address,company_url,company_contact_details,contact_number,email,photo_url,remarks,}) => (
  <div className="p-4 bg-white rounded-lg shadow-md flex flex-col items-center max-w-sm mx-auto text-center">
    <img
      src={photo_url || 'https://via.placeholder.com/150'}
      alt="HR Profile"
      className="w-24 h-24 rounded-full mb-4 object-cover"
    />
    <div>
      <h6 className="text-lg font-normal">{name}</h6>
      <p className="text-sm font-bold text-gray-600">{company_name}</p>
      <p className="text-sm text-gray-600">{category}</p>
      <p className="text-sm text-gray-600">{email}</p>
      <p className="text-sm text-gray-600">{contact_number}</p>
      <p className="text-sm text-gray-600">{company_address}</p>
      <a
        href={formatUrl(company_url)}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sm text-blue-500 underline"
      >
        Visit Company Website
      </a>
      <p className="text-sm text-gray-600 mt-2">{remarks}</p>
    </div>
  </div>
);

const HrDetails: React.FC<HrDetailsProps> = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return isMobile ? <HrDetailsMobile {...props} /> : <HrDetailsDesktop {...props} />;
};

export default HrDetails;
