import React, { useState } from 'react';
import { TextField, Grid, Button, Paper, useMediaQuery } from '@mui/material';

interface AddJobFormProps {
  newJob: {
    id?: string;
    title?: string; // Updated from position to title
    department?: string;
    location?: string;
    description?: string;
    experience_level?: string;
    salary?: string;
    company?: string;
    job_id?: string;
    valid_days?: string; // Added field for Valid Days
  };
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onAddJob: () => void;
  onCancel: () => void;
}

const AddJobForm: React.FC<AddJobFormProps> = ({ newJob, onInputChange, onAddJob, onCancel }) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const isMobile = useMediaQuery('(max-width: 768px)');

  const validate = (name: string, value: string) => {
    let error = '';
  
    if (value === '') {
      setErrors((prev) => ({ ...prev, [name]: '' }));
      return;
    }
    if (['title', 'department', 'location'].includes(name)) {
      if (!/^[a-zA-Z\s]+$/.test(value)) {
        error = `${name.replace('_', ' ')} should only contain letters.`;
      }
    }
    if (name === 'experience_level' && !/^[a-zA-Z0-9\s-]+$/.test(value)) {
      error = 'Experience level should contain letters, numbers, and hyphens only.';
    }
    if (name === 'salary' && !/^\d+(\s?-\s?\d+)?$/.test(value)) {
      error = 'Salary should be a number, or a range like "100000 - 150000" .';
    }
    if (name === 'valid_days' && !/^\d+$/.test(value)) {
      error = 'Valid days should only contain numbers.';
    }
      setErrors((prev) => ({ ...prev, [name]: error }));
  };
    
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    validate(name, value);
    onInputChange(e);
  };

  const fields = [
    { label: 'Title/Role', name: 'title', value: newJob.title || '', required: true },
    { label: 'Department', name: 'department', value: newJob.department || '' },
    { label: 'Location', name: 'location', value: newJob.location || '', required: true },
    { label: 'Experience Level', name: 'experience_level', value: newJob.experience_level || '', placeholder: 'e.g., 2-4 years' },
    { label: 'Salary', name: 'salary', value: newJob.salary || '', placeholder: 'e.g.,1,00,000 or 10,00,000 - 15,00,000' },
    { label: 'Valid Days', name: 'valid_days', value: newJob.valid_days || '', placeholder: 'e.g., 30' },
  ];

  return (
    <Paper  sx={{ p: 3,my: 2,...(isMobile ? { minHeight: '450px', maxHeight: '80vh', height: { md: '600px', xs: 'auto' } }
        : { minHeight: '400px', maxHeight: '70vh', height: { md: '600px', xs: 'auto' } })}}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onAddJob(); // Trigger the submission function
        }}
      >
        <Grid container spacing={2}>
          {fields.map((field) => (
            <Grid key={field.name} item xs={12} sm={6}>
              <TextField
                fullWidth
                label={field.label}
                name={field.name}
                value={field.value}
                onChange={handleChange}
                required={field.required || false}
                placeholder={field.placeholder || ''}
                error={!!errors[field.name]}
                helperText={errors[field.name]}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Description"
              name="description"
              value={newJob.description || ''}
              onChange={handleChange}
              required
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} >
            <Button type="submit" variant="contained" sx={{ mr: 2, backgroundColor: '#0778B6', color: '#FFFFFF' }}>
              Post Job
            </Button>
            <Button variant="outlined" onClick={onCancel} sx={{ color: 'grey.600', borderColor: 'grey.600' }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddJobForm;
