import React, { useState } from 'react';
import { Card, CardContent, CardActions, Typography, Chip, Divider, IconButton } from '@mui/material';
import { Edit, Delete, LocationOn, Work, Business, CurrencyRupee } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Job } from './HrPortfolio';

interface HRJobCardProps {
  job: Job;
  hrName: string | null;
  onEdit: () => void;
  onDelete: () => void;
}

const chipStyles = { backgroundColor: '#CAF0F8', color: '#0778B6', '& .MuiSvgIcon-root': { color: '#0778B6' } };

// Mobile version of the job card
const HRJobCardMobile: React.FC<HRJobCardProps> = ({ job, onEdit, onDelete, hrName }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleDelete = () => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the job: "${job.title}"?`);
    if (confirmDelete) {
      onDelete();
    }
  };

  return (
    <Card className="my-4 shadow-md" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <div className="flex flex-col gap-1">
          <Typography variant="h6" fontWeight="bold">{job.title}</Typography>
          <div className="flex items-center gap-x-5">
            <Typography variant="body2" color="text.secondary">
              Valid Till: {job.valid_days ? `${job.valid_days} days` : 'Not Specified'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Posted by: {job.posted_by}
            </Typography>
          </div>
        </div>
        <div className="flex gap-2 mt-2 flex-wrap">
          {job.department && <Chip size="small" icon={<Business style={chipStyles} />} label={job.department} className="bg-blue-100 text-blue-700" style={chipStyles} />}
          <Chip size="small" icon={<Work style={chipStyles} />} label={job.experience_level} className="bg-blue-100 text-blue-700" style={chipStyles} />
          <Chip size="small" icon={<LocationOn style={chipStyles} />} label={job.location} className="bg-blue-100 text-blue-700" style={chipStyles} />
          <Chip size="small" icon={<CurrencyRupee style={chipStyles} />} label={job.salary} className="bg-blue-100 text-blue-700" style={chipStyles} />
        </div>
        <div className="my-3">
          <Divider />
        </div>
        <Typography variant="body2" color="text.secondary" className="pr-2 whitespace-pre-wrap break-words">
          {showFullDescription ? job.description : `${job.description?.slice(0, 100)}`}
          {job.description && job.description.length > 150 && (
            <span className="text-back-prev-text cursor-pointer font-bold ml-1"
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? 'Show Less' : 'Read More'}
            </span>
          )}
        </Typography>
      </CardContent>
      <CardActions className="flex justify-end">
        <IconButton size="small" onClick={onEdit} className="text-blue-600" style={{ color: '#0778b6' }}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDelete} className="text-red-600" style={{ color: '#e5383b' }}>
          <Delete />
        </IconButton>
      </CardActions>
    </Card>
  );
};

// Desktop version with the same delete confirmation
const HRJobCardDesktop: React.FC<HRJobCardProps> = ({ job, onEdit, onDelete }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const handleDelete = () => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the job: "${job.title}"?`);
    if (confirmDelete) {
      onDelete();
    }
  };

  return (
    <Card className="my-4 shadow-md" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <div className="flex flex-col gap-1">
          <Typography variant="h6" fontWeight="bold">{job.title}</Typography>
          <div className="flex items-center gap-x-5">
            <Typography variant="body2" color="text.secondary">
              Valid Till: {job.valid_days ? `${job.valid_days} days` : 'Not Specified'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Posted by: {job.posted_by}
            </Typography>
          </div>
        </div>
        <div className="flex gap-2 mt-2 flex-wrap">
          {job.department && <Chip size="small" icon={<Business style={chipStyles} />} label={job.department} className="bg-blue-100 text-blue-700" style={chipStyles} />}
          <Chip size="small" icon={<Work style={chipStyles} />} label={job.experience_level} className="bg-blue-100 text-blue-700" style={chipStyles} />
          <Chip size="small" icon={<LocationOn style={chipStyles} />} label={job.location} className="bg-blue-100 text-blue-700" style={chipStyles} />
          <Chip size="small" icon={<CurrencyRupee style={chipStyles} />} label={job.salary} className="bg-blue-100 text-blue-700" style={chipStyles} />
        </div>
        <div className="my-3">
          <Divider />
        </div>
        <Typography variant="body2" color="text.secondary" className="pr-2 whitespace-pre-wrap break-words">
          {showFullDescription ? job.description : `${job.description?.slice(0, 150)}`}
          {job.description && job.description.length > 150 && (
            <span
              className="text-back-prev-text cursor-pointer font-bold ml-1"
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? 'Show Less' : 'Read More'}
            </span>
          )}
        </Typography>
      </CardContent>
      <CardActions className="flex justify-end">
        <IconButton size="small" onClick={onEdit} className="text-blue-600" style={{ color: '#0778b6' }}>
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={handleDelete} className="text-red-600" style={{ color: '#e5383b' }}>
          <Delete />
        </IconButton>
      </CardActions>
    </Card>
  );
};

const HRJobCard: React.FC<HRJobCardProps> = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return isMobile ? <HRJobCardMobile {...props} /> : <HRJobCardDesktop {...props} />;
};

export default HRJobCard;
