import React from 'react';
import { useMediaQuery } from 'react-responsive';
import EmailLoginDesktop from '../components/Emaillogin/emaillogindesktop';
import EmailLoginMobile from '../components/Emaillogin/emailloginMobile';

interface EmailLoginMainPageProps {
  onBack?: () => void;
  initialShowNameInput?: boolean;
  initialEmail?: string;
}

const EmailLoginMainPage: React.FC<EmailLoginMainPageProps> = ({ onBack,initialShowNameInput = false,initialEmail = ""  }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className="flex flex-col">
      {isMobile ? (
        <EmailLoginMobile onBack={onBack} initialShowNameInput={initialShowNameInput} initialEmail={initialEmail} />
      ) : (
        <EmailLoginDesktop onBack={onBack} initialShowNameInput={initialShowNameInput} initialEmail={initialEmail} />
      )}
    </div>
  );
};

export default EmailLoginMainPage;
