import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CreateNavbar from '../Navbar/Navbar'
import { isMobile as isMobileDevice } from 'react-device-detect'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import { coursesApi, ApiError, Course } from '../../api/FetchUserCourses'
import './EnrolledCourses.css'
import useIsIframe from '../CounsellorUser/CounsellorsClientPortFolio/common/IsIframe'
import { isUserTokenValid } from '../../components/common/ValidateToken'

const EnrolledCourses: React.FC = () => {
  const [isMobile, setIsMobile] = useState(isMobileDevice)
  const [courses, setCourses] = useState<Course[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const isIframe = useIsIframe()
  const shouldShowMobileView = isMobile || isIframe
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {setIsMobile(window.innerWidth <= 768)}
    window.addEventListener('resize', handleResize)
    return () => {window.removeEventListener('resize', handleResize)}
  }, [])

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const isValid = await isUserTokenValid()
        if (!isValid) {
          navigate('/login')
          return
        }
        const fetchedCourses = await coursesApi.fetchEnrolledCourses()
        setCourses(fetchedCourses)
      } catch (err) {
        setError(err instanceof ApiError ? err.message : 'An error occurred')
      } finally {
        setIsLoading(false)
      }
    }

    fetchCourses()
  }, [])

  const handleExplore = () => {
    const LMSTOKEN = localStorage.getItem('LMSToken')
    const url = LMSTOKEN ? `https://trainings.skilljourney.in/store/courses?ssoToken=${LMSTOKEN}` : 'https://trainings.skilljourney.in/store/courses'
    if (isIframe) {
      window.open(url) 
    } else {
      window.location.href = url 
    }
  }
  
  return (
    <div className="enrolled-courses">
      {isLoading && (
        <div className="loading-overlay">
          <LoadingSpinner />
        </div>
      )}
      <CreateNavbar page="home" />
      <div className={`courses-container ${shouldShowMobileView ? 'mobile' : ''}`}>
        <div className="courses-wrapper">
          {error ? (
            <div className="error">{error}</div>
          ) : courses.length > 0 ? (
            <div className="courses-grid">
              {courses.map((course, index) => (
                <div key={index} className="course-card">
                  <div className="card-content">
                    <h3 className="course-title">{course.title}</h3>
                    <div className="registration-info">
                      <p className="registration-date">
                        Date of Registration:{' '}
                        <span>{course.registrationDate}</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-courses">
              <p className="no-courses-text">No trainings records available</p>
            </div>
          )}

          <div className="explore-section">
            <button
              className="explore-btn"
              onClick={handleExplore}
            >
              Explore Courses
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnrolledCourses
