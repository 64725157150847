import React, { useEffect } from "react";
import CreateNavbar from "../Navbar/Navbar";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Cancel';
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MIT_logo from '../../assets/img/CollegeDashboard/MIT_logo.png';
import College_Filter from '../../assets/img/CollegeDashboard/College_Filter.png';
import { searchStudents } from "../../api/College";
import LoadingSpinner from "../common/LoadingSpinner";
import { isUserTokenValid } from "../common/ValidateToken";

export interface Student {
  id?: string; 
  name: string;
  ProfileUrl: string;
  education: string;
  cgpa: string;
  highlights: string;
  email?: string;
  college_name?: string;
  contact_number?: number;
  degree?: string;
  department?: string;
  year?: number;
}

interface Filter {
  category: string;
  value: string | number;
}

interface FilterOptions {
  degrees: string[];
  departments: string[];
  years: number[];
}

const CollegeDashboardMobile: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
  const navigate = useNavigate();
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    degrees: [],
    departments: [],
    years: []
  });
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [initialStudents, setInitialStudents] = useState<Student[]>([]);
  const [lastSearchResults, setLastSearchResults] = useState<Student[]>([]);
  const location = useLocation();
  const collegeName = location.state?.collegeName || "";

  useEffect(() => {
    const initialize = async () => {
        const isValid = await isUserTokenValid();
        if (!isValid) {
          navigate('/college-login', { state: { page: "college-dashboard" } }); 
        }
        setLoading(false);
    };
    initialize();
  });

  const transformStudentData = (studentsData: Student[]) => {
    return studentsData.map((student) => ({
      id: student.email,
      name: student.name,
      ProfileUrl: student.ProfileUrl || "https://via.placeholder.com/150",
      education: `${student.degree} in ${student.department}`,
      cgpa: student.cgpa.toString(),
      highlights: student.highlights,
      year: Number(student.year),
      degree: student.degree,
      department: student.department
    }));
  };

  const fetchStudentsData = async (params: Record<string, any>, isSearch: boolean = false) => {
    try {
      setLoading(true);
      const response = await searchStudents({
        college_name: collegeName,
        ...params
      });
      const studentsData = response.data.students as Student[];
      const transformedData = transformStudentData(studentsData);
      
      if (isSearch) {
        setLastSearchResults(transformedData);  
      }
      setStudents(transformedData);
    } catch (error) {
      console.error("Failed to fetch students:", error);
      setStudents([]);
      if (isSearch) {
        setLastSearchResults([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeDashboard = async () => {
      try {
        setLoading(true);
        const response = await searchStudents({ college_name: collegeName });
        const studentsData = response.data.students as Student[];

        const uniqueOptions: FilterOptions = {
          degrees: Array.from(new Set(studentsData
            .map(student => student.degree)
            .filter((degree): degree is string => degree !== undefined))),
          departments: Array.from(new Set(studentsData
            .map(student => student.department)
            .filter((department): department is string => department !== undefined))),
          years: Array.from(new Set(studentsData
            .map(student => student.year)
            .filter((year): year is number => year !== undefined)))
        };
        setFilterOptions(uniqueOptions);
        
        const transformedStudents = transformStudentData(studentsData);
        setStudents(transformedStudents);
        setInitialStudents(transformedStudents);
      } catch (error) {
        console.error("Failed to initialize dashboard:", error);
        setStudents([]);
        setInitialStudents([]);
      } finally {
        setLoading(false);
      }
    };

    initializeDashboard();
  }, [collegeName, refreshTrigger]);
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (!query.trim()) {
      setStudents(initialStudents);
      setLastSearchResults([]);
      return;
    }
  };

  const handleSearchClick = async () => {
    const searchParams: Record<string, any> = {};
    
    if (searchQuery.trim()) {
      searchParams.name = searchQuery.trim();
    } else {
      if (selectedFilters.length > 0) {
        handleApplyFilters();
      } else {
        setStudents(initialStudents);
        setLastSearchResults([]);
      }
      return;
    }
  
    if (selectedFilters.length > 0) {
      const filterParams = selectedFilters.reduce((acc, filter) => {
        const categoryKey = filter.category.toLowerCase();
        if (categoryKey === 'year') {
          acc[categoryKey] = Number(filter.value);
        } else {
          if (!acc[categoryKey]) {
            acc[categoryKey] = [];
          }
          acc[categoryKey].push(String(filter.value));
        }
        return acc;
      }, {} as Record<string, any>);
  
      Object.keys(filterParams).forEach((key) => {
        if (Array.isArray(filterParams[key])) {
          filterParams[key] = filterParams[key].join(',');
        }
      });
  
      Object.assign(searchParams, filterParams);
    }
  
    await fetchStudentsData(searchParams, true);
  };
  const handleCardClick = (id: string | undefined) => {
    if (!id) return;
    const selectedStudent = students.find(student => student.id === id);
    if (selectedStudent) {
      navigate(`/college-portfolio`, { state: selectedStudent });
    }
  };

  const handleFilterSelect = (category: string, value: string | number, checked: boolean) => {
    setSelectedFilters(prev => 
      checked 
        ? [...prev, { category, value }]
        : prev.filter(filter => !(filter.category === category && filter.value === value))
    );
  };

  const isFilterSelected = (category: string, value: string | number) => {
    return selectedFilters.some(
      filter => filter.category === category && filter.value === value
    );
  };

  const handleRemoveFilter = async (filterToRemove: Filter) => {
    const updatedFilters = selectedFilters.filter(
      filter => !(filter.category === filterToRemove.category && filter.value === filterToRemove.value)
    );
    setSelectedFilters(updatedFilters);
  
    if (updatedFilters.length === 0) {
      if (searchQuery.trim()) {
        await fetchStudentsData({ name: searchQuery.trim() }, true);
      } else {
        setStudents(initialStudents);
      }
      return;
    }

    const filterParams = updatedFilters.reduce((acc, filter) => {
      const categoryKey = filter.category.toLowerCase();
      if (categoryKey === 'year') {
        acc[categoryKey] = Number(filter.value);
      } else {
        if (!acc[categoryKey]) {
          acc[categoryKey] = [];
        }
        acc[categoryKey].push(String(filter.value));
      }
      return acc;
    }, {} as Record<string, any>);
  
    Object.keys(filterParams).forEach((key) => {
      if (Array.isArray(filterParams[key])) {
        filterParams[key] = filterParams[key].join(',');
      }
    });
  
    if (searchQuery.trim()) {
      filterParams.name = searchQuery.trim();
    }
  
    await fetchStudentsData(filterParams);
  };

  const handleClearAll = () => {
    setSelectedFilters([]);
    if (lastSearchResults.length > 0) {
      setStudents(lastSearchResults);
    } else {
      setStudents(initialStudents);
    }
    setShowFilters(false);
  };

  const handleApplyFilters = async () => {
    if (selectedFilters.length === 0) {
      handleClearAll();
      return;
    }
  
    const filterParams = selectedFilters.reduce((acc, filter) => {
      const categoryKey = filter.category.toLowerCase();
      if (categoryKey === 'year') {
        acc[categoryKey] = Number(filter.value);
      } else {
        if (!acc[categoryKey]) {
          acc[categoryKey] = [];
        }
        acc[categoryKey].push(String(filter.value));
      }
      return acc;
    }, {} as Record<string, any>);
  
    Object.keys(filterParams).forEach((key) => {
      if (Array.isArray(filterParams[key])) {
        filterParams[key] = filterParams[key].join(',');
      }
    });
  
    if (searchQuery.trim()) {
      filterParams.name = searchQuery.trim();
    }
    await fetchStudentsData(filterParams);
    setShowFilters(false);
  };

  return (
    <div className="h-dvh overflow-x-hidden bg-[#F5F5F5]">
      <div className="fixed top-0 left-0 right-0 z-50">
      <CreateNavbar page="college-dashboard" />
      </div>
      <div className="pt-[64px]"> 
        <div className="sticky top-14 z-40 bg-[#F5F5F5]">
          <div className="flex items-center justify-between p-4 gap-4">
            <div className="flex-1">
              <div className="flex items-center bg-white border border-gray-300 rounded-md overflow-hidden shadow-md">
                <SearchIcon className="text-gray-300 ml-4 h-6 w-6 shrink-0" />
                <input
                  type="text"
                  placeholder="Search Student"
                  className="flex-grow min-w-0 px-2 py-3 text-sm focus:outline-none bg-white"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <button
                  onClick={handleSearchClick}
                  className="bg-[#0778B6] text-white text-sm px-3 py-2 mr-1 hover:bg-blue-700 transition rounded-md duration-300 shrink-0"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="shrink-0">
              <img src={MIT_logo} alt="MIT Logo" className="w-14 h-14" />
            </div>
          </div>
  
          <div className="px-4 pb-2">
            <div className="flex items-center gap-4">
              <div className="flex-1 overflow-x-auto py-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                <div className="flex items-center gap-2">
                  {selectedFilters.map((filter, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-1 bg-[#ECFAFD] text-[#0778B6] border border-[#0778B6] px-2 py-1 rounded-full text-sm font-medium whitespace-nowrap"
                    >
                      <span>{filter.value}</span>
                      <button
                        onClick={() => handleRemoveFilter(filter)}
                        className="ml-1 hover:text-blue-600"
                      >
                        <CloseIcon fontSize="small" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="shrink-0">
                <button
                  className="bg-white p-2 rounded-md shadow-sm hover:shadow-md transition-shadow"
                  onClick={() => setShowFilters(true)}
                >
                  <img src={College_Filter} alt="Filter" className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
  
        {showFilters && (
          <div
            className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={() => setShowFilters(false)}
          >
            <div
              className="absolute top-48 right-6 bg-white rounded-md shadow-lg w-[60%] max-w-md p-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Filters</h3>
              </div>
              <div className="max-h-[50vh] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                <div className="mb-4">
                  <h4 className="text-base font-semibold">Degree</h4>
                  {filterOptions.degrees.map((degree) => (
                    <label key={degree} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={isFilterSelected('Degree', degree)}
                        onChange={(e) => handleFilterSelect('Degree', degree, e.target.checked)}
                      />
                      <span>{degree}</span>
                    </label>
                  ))}
                </div>
  
                <div className="mb-4">
                  <h4 className="text-base font-semibold">Department</h4>
                  {filterOptions.departments.map((department) => (
                    <label key={department} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={isFilterSelected('department', department)}
                        onChange={(e) => handleFilterSelect('department', department, e.target.checked)}
                      />
                      <span>{department}</span>
                    </label>
                  ))}
                </div>
  
                <div>
                  <h4 className="text-base font-semibold">Year</h4>
                  {filterOptions.years.map((year) => (
                    <label key={year} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={isFilterSelected('Year', year)}
                        onChange={(e) => handleFilterSelect('Year', year, e.target.checked)}
                      />
                      <span>{year} Year</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center border-t border-gray-300 pt-2 mt-2">
                <button
                  className="text-gray-600 text-sm font-semibold"
                  onClick={handleClearAll}
                >
                  Clear All
                </button>
                <button
                  className="text-custom-blue text-sm font-semibold py-2"
                  onClick={handleApplyFilters}
                >
                  Apply All ({selectedFilters.length})
                </button>
              </div>
            </div>
          </div>
        )}
  
        {loading ? (
            <div>
            <LoadingSpinner />
          </div>
        ) : students.length === 0 ? (
          <div className="flex justify-center items-center h-[70vh]">
            <p className="text-gray-600 text-lg font-semibold text-center">No student record found</p>
          </div>
        ) : (
        <div className="grid grid-cols-1 gap-6 mt-4 mb-8 px-5">
          {students.map((student) => (
            <div
              key={student.id || student.email}
              className="bg-white p-4 rounded-lg shadow-md border-t-4 border-custom-orange transition-transform transform hover:scale-105 hover:shadow-2xl flex"
              onClick={() => handleCardClick(student.id || student.email)}
            >
              <div className="flex items-center">
                <img
                  src={student.ProfileUrl}
                  alt={student.name}
                  className="w-20 h-20 rounded-full border-4 border-customBlue mr-4"
                />
                <div>
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">
                    {student.name}
                  </h3>
                  <p className="text-gray-600 text-sm mb-1">
                    <strong>Education:</strong> {student.education}
                  </p>
                  <div className="flex flex-row  ">
                    <p className="text-gray-600 text-sm mb-1 mr-[13px]">
                      <strong>Year:</strong> {student.year}
                    </p>
                    <p className="text-gray-600 text-sm mb-1">
                      <strong>CGPA:</strong> {student.cgpa}
                    </p>
                  </div>
                  <p className="text-gray-600 text-sm mb-1">
                    <strong>Highlights:</strong> {student.highlights}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        )}
      </div>
    </div>
  );  
};

export default CollegeDashboardMobile;