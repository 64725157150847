import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PortfolioMobile from '../components/user/portfolio/mobile/PortfolioMobile';
import PortfolioDesktop from '../components/user/portfolio/desktop/PortfolioDeskptop';
import { isUserTokenValid } from '../components/common/ValidateToken';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';


const Portfolio: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
   useEffect(() => {
          const initialize = async () => {
              const isValid = await isUserTokenValid();
              if (!isValid) {
                navigate('/login', { state: { page: "portfolio" } }); 
              }
              setLoading(false);
          };
          initialize();
      });
      if (loading) {
        return <LoadingSpinner />; // Show spinner while loading
    }
  return (
    <div className="flex flex-col">
      {/* <CreateNavbar page='home' /> */}
      {isMobile ? (
        <PortfolioMobile/>
      ) : (
        <PortfolioDesktop/>
      )}
    </div>
  );
};

export default Portfolio;
